import * as Msdyn365 from '@msdyn365-commerce/core';
import { IDeleteTransactionResponse, IDeleteTransactionRequest, mFIDeleteTransactionAsync } from './progressive-proxy-files';

/**
 * ProgressiveDeleteTransaction Input Action
 */

export class ProgressiveDeleteTransactionInput extends Msdyn365.CommerceEntityInput implements Msdyn365.IActionInput {
    public deleteContract: IDeleteTransactionRequest;

    constructor(leaseContract: IDeleteTransactionRequest) {
        super({ shouldCacheOutput: true, cacheObjectType: 'ProgressiveDeleteTransaction', cacheKey: 'ProgressiveDeleteTransaction' });
        this.deleteContract = leaseContract;
    }

    public getCacheKey = () => `ProgressiveDeleteTransaction`;
    public getCacheObjectType = () => 'ProgressiveDeleteTransaction';
    public dataCacheType = (): Msdyn365.CacheType => 'none';
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new ProgressiveDeleteTransactionInput({ ApplicationId: 0 });
};

/**
 * ProgressiveDeleteTransactionAction function receives applicationId and deletes the lease contract
 */
export async function ProgressiveDeleteTransactionAction(input: ProgressiveDeleteTransactionInput, ctx: Msdyn365.IActionContext): Promise<IDeleteTransactionResponse | null> {
    try {
        const deleteContractResponse = await mFIDeleteTransactionAsync({ callerContext: ctx }, input.deleteContract);
        return deleteContractResponse.Transaction || null;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}

export const IProgressiveDeleteTransactionAction = Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IDeleteTransactionResponse>>ProgressiveDeleteTransactionAction,
    input: createInput,
    id: 'ProgressiveDeleteTransaction'
});