import { Cart, CartLine, DataValidationFailure } from '@msdyn365-commerce/retail-proxy';
import { ICartProductPrice } from '../cart-utils';

export interface IDataValidationErrors {
    CorrelationId?: string;
    ErrorResourceId?: string;
    LocalizedMessage?: string;
    ValidationResults?: DataValidationFailure[];
}

const callFS = (name?: string, detailsObject?: any) => {
    try {
        // If FS doesn't exist in the global scope, set as false.
        const FS = global.hasOwnProperty('FS') ? global['FS'] : false;
        FS && FS.event(name, detailsObject);
    } catch (e) {
        console.log('FS View Errors', e);
    }
};
export const eventFullStoryAPISuccess = async (name?: string, pathName?: string, objToCall?: Cart) => {
    const detailsObject = {
        pathName,
        status: 'success'
    };
    callFS(name, detailsObject);
};

export const eventFullStoryPriceMismatch = async (name?: string, pathName?: string, objToCall?: ICartProductPrice[], cartLines?: CartLine[] | undefined , tradeIds?: number[] ) => {
    const PdpPriceArrays: string[] = [];
    const idsArrays: string[] = [];
    const cartPriceArrays: string[] = [];
    const tradeLines = tradeIds && tradeIds.length > 0 ? true : false;
    objToCall?.forEach((items) => {
        PdpPriceArrays.push(`${items.price}`);
        idsArrays.push(`${items.variantRecordId}`);
        const checkCartPrice = cartLines?.find((lines) => lines.ProductId === items.variantRecordId)?.Price;
        if(checkCartPrice){
            cartPriceArrays.push(`${checkCartPrice}`);
        }
    });
    const detailsObject = {
        pathName,
        id: idsArrays?.toString(),
        pdp_price: PdpPriceArrays?.toString(),
        cart_price: cartPriceArrays?.toString(),
        ...(tradeLines && {tradeAgreement: "trade agreement is missing"})
    };
    callFS(name, detailsObject);
};

export const eventFullStoryFailAPI = async (
    name?: string,
    pathName?: string,
    objToCall?: {
        error: {
            data?: IDataValidationErrors;
            message?: string;
            statusCode?: number;
        };
    }
) => {
    const detailsObject = {
        pathName,
        status: objToCall?.error?.statusCode,
        message: objToCall?.error?.message,
        correlationId: objToCall?.error?.data?.CorrelationId,
        validationError: objToCall?.error?.data?.ValidationResults?.[0]?.ErrorContext
    };
    callFS(name, detailsObject);
};
