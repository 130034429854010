import MsDyn365, { IActionContext } from '@msdyn365-commerce/core';
import {IProductDetails,IProductBonusItems, IProductBonusWithBrandName } from '../modules/mfrm-cart/mfrm-cart';
import {IMFIBonusItemsResponse, IMFIGetBonusCouponsDetailsResponse} from '../actions/BonusItemDataServiceEntities.g';
import { SimpleProduct, CartLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { setCookie } from '../common/cookies/cookie-utils';
import { LocalStorageKeys, MFRMProductTypes } from './enum';
import { getByIdAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { IMfrmAddress } from '../modules/mfrm-checkout-shipping-address';
import { ICoreProductsCartData } from '../themes/mattressfirm/views/checkout.view';

export interface IMFRMDateGroupedProducts {
    dateStr: string;
    days?: string;
    productVariantRecordIds: number[];
    productItemIds: string[];
    productsType: string;
}

export interface IExpressFlow {
    paymentEdit: boolean
}

export interface ICartProductPrice {
    variantRecordId?: number;
    price?: number;
}

/** interface for parcel inventory details */
export interface IParcelInventoryDetails {
    itemId: string | undefined;
    location: string | undefined;
    productId: string | undefined;
    quantity: string | undefined;
}
export interface ICartIconData {
    iconUrl?: string;
    altText?: string;
    ariaLabel?: string;
}
export interface IEyeBrowIcon {
    iconName?: string;
    iconURL?: string;
}

const getSetLocalStorage = (key: string, getSet: string, valueToSet?: any, returnType?: any) => {
    // The type of the value to return (must be added).
    if (MsDyn365.isBrowser) {
        switch (getSet) {
            case 'set':
                return localStorage.setItem(key, JSON.stringify(valueToSet || returnType));
            case 'get':
                const storedValue = localStorage.getItem(key);
                try {
                    return storedValue ? JSON.parse(storedValue) : (returnType ?? "");
                } catch {
                    // JSON.parse will throw an error If it's stored as string; return string value in that case
                    return storedValue;
                }
            case 'remove':
                return localStorage.removeItem(key);
            default:
                return returnType;
        }
    } else {
        return returnType;
    }
};

export const getSetProductData = (getSet: string, products?: IProductDetails[]) => {
    products?.length && products.length > 0 && setCoreProductCookie(products); // Set cookie if it has core products
    return getSetLocalStorage('product_details', getSet, products, []);
};

// Following function sets cookie to check if cart has core item; Cookie is needed to access it on server side for skeleton on checkout
const setCoreProductCookie = (products: IProductDetails[]) => {
    for (const psp of products) {
        if (psp.typeOfProduct.toLowerCase().trim() === MFRMProductTypes.core) {
            setCookie('has-core-product', true);
            return;
        }
    }
    setCookie('has-core-product', false);
};

export const getSetProductSpec = (getSet: string, products?: IProductDetails[]) => {
    return getSetLocalStorage('product_specification', getSet, products, []);
};

export const getSetPromoCodeApplyList = (getSet: string) => {
    return getSetLocalStorage('promo_code_apply_list', getSet, [], []);
};

export const getSetPromoCode = (getSet: string) => {
    return getSetLocalStorage('promoCode', getSet, [], []);
};

export const getSetGrandTotal = (getSet: string, total?: string) => {
    return getSetLocalStorage('grand_total', getSet, total, 0);
};

export const getSetBonusItemProd = (getSet: string, globalBonusItems?: IMFIBonusItemsResponse[])=>{
    return getSetLocalStorage('BonusItemProduct', getSet, globalBonusItems, []);
};

export const getSetSelectedBonusItem = (getSet: string, bonusItemArray?: IProductBonusItems[]) => {
    return getSetLocalStorage('selectedBonasItems', getSet, bonusItemArray, []);
};

export const getSetBonusCouponDetail = (getSet: string, getCoupons?: IMFIGetBonusCouponsDetailsResponse[])=>{
    return getSetLocalStorage('bonusCouponsDetail', getSet, getCoupons, []);
};

export const getSetPrevBonasModalState = (getSet: string, previousBonasModal?: IProductBonusItems[]) => {
    return getSetLocalStorage('previousBonasModalState', getSet, previousBonasModal, []);
};

export const getSetBonusItemBrandName = (getSet: string, bonusItemsBrandName?: IProductBonusWithBrandName[]) => {
    return getSetLocalStorage('bonusItmesWithBrand', getSet, bonusItemsBrandName, []);
};

export const getSetDateGroupedProducts = (getSet: string, products?: IMFRMDateGroupedProducts[]) => {
    return getSetLocalStorage('date_grouped_products', getSet, products, []);
};

export const getSetExpressFlow = (getSet: string, express?: IExpressFlow) => {
    return getSetLocalStorage('express_flow', getSet, express, []);
};
export const getSetWmapCartLines = (getSet: string, products?: { product: SimpleProduct; count: number }[]) => {
    return getSetLocalStorage('wmapCartLines', getSet, products, []);
};

export const getSetCartProductPrice = (getSet: string, priceDetails?: ICartProductPrice[]) => {
    return getSetLocalStorage('cart_product_price', getSet, priceDetails, []);
};

export const getSetParcelInventoryData = (getSet: string, details?: IParcelInventoryDetails[]) => {
    return getSetLocalStorage('parcel_inventory_details', getSet, details, []);
};

export const getSetDropshipLatestDeliveryMsg = (getSet: string, msg?: string) => {
    return getSetLocalStorage(LocalStorageKeys.DropshipLatestDeliveryMsg, getSet, msg, '');
};

export const getSetParcelLatestDeliveryMsg = (getSet: string, msg?: string) => {
    return getSetLocalStorage(LocalStorageKeys.ParcelLatestDeliveryMsg, getSet, msg, '');
};

export const getSetCustomerPhone = (getSet: string, phone?: string) => {
    return getSetLocalStorage(LocalStorageKeys.CustomerPhone, getSet, phone, '');
};

export const getSetSelectedDeliveryService = (getSet: string, phone?: string) => {
    return getSetLocalStorage(LocalStorageKeys.SelectedDeliveryService, getSet, phone, '');
};

export const getSetCustomerBillingSameDelivery = (getSet: string, isSame?: string) => {
    return getSetLocalStorage(LocalStorageKeys.CustomerBillingSameDelivery, getSet, isSame, '');
};

export const getSetRecycleFee = (getSet: string, fee?: string) => {
    return getSetLocalStorage(LocalStorageKeys.RecycleFee, getSet, fee, '');
};

export const getSetSelectedDeliverySlot = (getSet: string, slot?: string) => {
    return getSetLocalStorage('selected_delivery_slot ', getSet, slot, '');
};

export const getSetSelectedDeliveryDate = (getSet: string, date?: Date) => {
    return getSetLocalStorage('selected_delivery_date', getSet, date, '');
};
export const getSetCoreProductDeliveryMsg = (getSet: string, msg?: string) => {
    return getSetLocalStorage('coreprod_deliverymsg', getSet, msg, '');
};

export const getSetDefaultDeliverySelected = (getSet: string, selected?: boolean) => {
    return getSetLocalStorage('default_delivery_selected', getSet, selected, '');
};

export const getSetTimeSlot = (getSet: string, selected?: string) => {
    return getSetLocalStorage('time_slot', getSet, selected, '');
};

export const getSetDaySlot = (getSet: string, selected?: string) => {
    return getSetLocalStorage('day_slot', getSet, selected, '');
};

export const getSetServiceName = (getSet: string, selected?: string) => {
    return getSetLocalStorage('service_name', getSet, selected, '');
};

export const getSetSelectedServicePrice = (getSet: string, selected?: number) => {
    return getSetLocalStorage('selected_service_price', getSet, selected, 0);
};

export const getSetCoreShippingMsg = (getSet: string, selected?: string) => {
    return getSetLocalStorage('core_shipping_msg', getSet, selected, '');
};

export const getSetEcomMonthlyAmount = (getSet: string, selected?: number) => {
    return getSetLocalStorage('ecom_monthly_amount', getSet, selected, '');
};

export const getSetExpressType = (getSet: string, value?: string) => {
    return getSetLocalStorage('express-type', getSet, value, ''); /* Selected express payment method name; Also used in sales header attributes */
};

export const getSetApplepayCheckoutError = (getSet: string, value?: string) => {
    return getSetLocalStorage('applepay-checkout-error', getSet, value, ''); /*  It indicates that retrieveCardPayment/checkout API for applepay is failed on the Cart/Checkout page, It displays error above the shipping address */
};

export const getSetApplepayError = (getSet: string, value?: string) => {
    return getSetLocalStorage('applepay-error', getSet, value, ''); /* It indicates that retrieveCardPayment API for applepay is failed on the Cart page, It displays error above the shipping address */
};

export const getSetApplepayIFrameError = (getSet: string, value?: string) => {
    return getSetLocalStorage('applepay-iframe-error', getSet, value, ''); /* It indicates that applepay iframe has an error and if its true; applepay button will not be shown and also stop showing loader for applepay */
};

export const getSetGetCardApplepayPass = (getSet: string, value?: string) => {
    return getSetLocalStorage('getcardapplepay-pass', getSet, value, ''); /* It indicates that getCardPaymentAcceptPoint() API gives success for applepay; If it's true, 'applepay-iframe-error' will be checked after that */
};

export const getSetGetCardApplepayFail = (getSet: string, value?: string) => {
    return getSetLocalStorage('getcardapplepay-fail', getSet, value, ''); /* It indicates that getCardPaymentAcceptPoint() API is failed for applepay; If it's true =>  1. 'applepay-iframe-error' will be checked after that; 2. it'll display error on the respective page cart/checkout */
};

export const getSetLoadExpress = (getSet: string, value?: string) => {
    return getSetLocalStorage('loadExpress', getSet, value, ''); /* Avoid display loader on checkout page in case of express checkout */
};

export const getSetPaypalCheckoutError = (getSet: string, value?: string) => {
    return getSetLocalStorage('paypal-checkout-error', getSet, value, ''); /* It indicates that retrieveCardPayment/checkout API for paypal is failed on the Cart/Checkout page, It displays error above the shipping address */
};

export const getSetPaypalError = (getSet: string, value?: string) => {
    return getSetLocalStorage('paypal-error', getSet, value, ''); /* It indicates that retrieveCardPayment API for paypal is failed on the Cart page, It displays error above the shipping address */
};

export const getSetPaypalIFrameError = (getSet: string, value?: string) => {
    return getSetLocalStorage('paypal-iframe-error', getSet, value, ''); /* It indicates that paypal iframe has an error and if its true; paypal button will not be shown and also stop showing loader for paypal */
};

export const getSetGetCardExpressPass = (getSet: string, value?: string) => {
    return getSetLocalStorage('getcardexpress-pass', getSet, value, ''); /* It indicates that getCardPaymentAcceptPoint() API gives success for paypal; If it's true, 'paypal-iframe-error' will be checked after that */
};

export const getSetGetCardExpressFail = (getSet: string, value?: string) => {
    return getSetLocalStorage('getcardexpress-fail', getSet, value, ''); /* It indicates that getCardPaymentAcceptPoint() API is failed for paypal; If it's true =>  1. 'paypal-iframe-error' will be checked after that; 2. it'll display error on the respective page cart/checkout */
};

export const getSetExpressAddress = (getSet: string, value?: IMfrmAddress) => {
    return getSetLocalStorage('express-address', getSet, value, []); /* Address from express address is saved. If user updates it by clicking on edit customer info, updated address will be saved in it. But if user re-authenticate, it will reset the old address and will save the new one */
};

export const getSetExpressPageLoad = (getSet: string, value?: string) => {
    return getSetLocalStorage('express_page_load', getSet, value, ''); /* It checks if express checkout page is reloaded */
};

export const getSetThankyou = (getSet: string, value?: any) => {
    return getSetLocalStorage('thankyou_payment_details', getSet, value, '');
};

export const getSetEcomFinanceOption = (getSet: string, value?: string) => {
    return getSetLocalStorage('Ecom_FinanceOption', getSet, value, '');
};

export const getSetEcomFinancePlanId = (getSet: string, value?: string) => {
    return getSetLocalStorage('Ecom_FinancePlanId', getSet, value, '');
};

export const getSetCoreProdMsg = (getSet: string, value?: string) => {
    return getSetLocalStorage('payment_review_latest_core_prod_msg', getSet, value, '');
};

export const getSetBillingAddress = (getSet: string, value?: any) => {
    return getSetLocalStorage('billing_address', getSet, value, {});
};

export const getSetPaymentLoader = (getSet: string, value?: string) => {
    return getSetLocalStorage('paymentloader', getSet, value, '');
};

export const getSetLastPaymentConnector = (getSet: string, value?: string) => {
    return getSetLocalStorage('lastPaymentConnector', getSet, value, '');
};

export const getSetPaymentRadioName = (getSet: string, value?: string) => {
    return getSetLocalStorage('payment_radio_name', getSet, value, '');
};

export const getSetPaymentRadioIndex = (getSet: string, value?: string) => {
    return getSetLocalStorage('payment_radio_index', getSet, value, '');
};

export const getSetSelectedPaymentRadio = (getSet: string, value?: string) => {
    return getSetLocalStorage('selectedPaymentRadio', getSet, value, '');
};

export const getSetPaymentInfoForOCPage = (getSet: string, value?: any) => {
    return getSetLocalStorage('temp_card_payment_info_for_order_confirmation', getSet, value, '');
};

export const getSetCurrentStep = (getSet: string, value?: number) => {
    return getSetLocalStorage('current_step', getSet, value, 0);
};

export const getSetCheckoutCurrentStep = (getSet: string, value?: number) => {
    return getSetLocalStorage('checkout_current_step', getSet, value, 0);
};

export const getSetCartCoreProductInfo = (getSet: string, value?: ICoreProductsCartData[]) => {
    return getSetLocalStorage('cart_core_prods_info', getSet, value, []);
};

export const getSetUpdatedEmail = (getSet: string, value?: string) => {
    return getSetLocalStorage('updated_email ', getSet, value, '');
};

export const getSetSelectedDeliverySlotZone = (getSet: string, zone?: string) => {
    return getSetLocalStorage('selected_delivery_slot_zone ', getSet, zone, '');
};

const processCartLine = async (cartLine: CartLine, actionContext: IActionContext, channelId: number) => {
    try {
        const productResponse = await getByIdAsync(
            {callerContext: actionContext, queryResultSettings: {Paging: {Top: 1}}},
            cartLine.ProductId || 0,
            channelId
        );

        if (productResponse?.DefaultUnitOfMeasure) {
            // Adding unit of measure for order confirmation emails to get Ecom_variantId attribute
            cartLine.UnitOfMeasureSymbol = productResponse.DefaultUnitOfMeasure;
            return cartLine;
        } else {
            // Handle the case where DefaultUnitOfMeasure is not available
            return cartLine;
        }
    } catch (error) {
        // Handle errors if getByIdAsync fails for a specific productId
        console.error(`Error processing ProductId ${cartLine.ProductId}: ${error.message}`);
        return cartLine;
    }
};

export const addUnitOfMeasureInAddCartLine = async (cartLines: CartLine[], actionContext: IActionContext, channelId: number) => {
    const promises = cartLines
        .filter((cartLine: any) => !cartLine.DefaultUnitOfMeasure)
        .map((cartLine: any) => processCartLine(cartLine, actionContext, channelId));

    try {
        return await Promise.all(promises);
    } catch (error) {
        // Handle errors if any of the promises fail
        console.error(`Error processing cartLines: ${error}`);
        return cartLines;
    }
};