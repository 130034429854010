import { IGridSettings, IImageSettings, Image } from '@msdyn365-commerce/core';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
import { IBrandListData } from '../modules/mfrm-buybox/mfrm-buybox.view';
import { IProductBonusWithBrandName } from '../modules/mfrm-cart/mfrm-cart';
import {hasArray} from './emtpyChecker';
import MsDyn365 from '@msdyn365-commerce/core';

const fallBackBrandAttribute = 'VENDOR BRAND';

// tslint:disable-next-line: cyclomatic-complexity
export const parseBrandData = (
    attributes: AttributeValue[] | undefined,
    backendAttribute: string,
    gridSettings: IGridSettings,
    renderBrandNameOnly: Boolean = false,
    brandList: IBrandListData[],
    imageSettings?: IImageSettings
): JSX.Element | undefined => {
    const attribute = backendAttribute || fallBackBrandAttribute;
    const productBrandName =
        attributes &&
        attributes.find(currAttribute => currAttribute.Name && currAttribute.Name.toLowerCase().trim() === attribute.toLowerCase().trim())
            ?.TextValue;

    if (renderBrandNameOnly) {
        return <span className='msc-product__brand-name'>{productBrandName}</span>;
    }

    const selectedBrand =
        (brandList &&
            productBrandName &&
            brandList.find(
                (brand: IBrandListData) => brand && brand.brandName.toLowerCase().trim() === productBrandName.toLowerCase().trim()
            )) ||
        undefined;

    if (!selectedBrand) {
        return undefined;
    }

    const copySelectedBrand: IBrandListData = selectedBrand;
    // set alt text to brand name if no alt text is configured
    if (copySelectedBrand && _useDefaultAltText(copySelectedBrand) && copySelectedBrand.brandLogo) {
        copySelectedBrand.brandLogo.altText = productBrandName;
    }

    // set height to configured value or the default
    const height = copySelectedBrand && copySelectedBrand.logoHeight !== undefined ? copySelectedBrand.logoHeight : 22;
    const brandImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=0&h=25&m=6`, w: 0, h: 25 },
            lg: { q: `w=0&h=25&m=6`, w: 0, h: 25 },
            xl: { q: `w=0&h=25&m=6`, w: 0, h: 25 }
        },
        lazyload: false
    };

    const brandLogo = copySelectedBrand && copySelectedBrand.brandLogo && (
        <Image
            {...copySelectedBrand.brandLogo}
            className={'mfrm-brand__logo'}
            gridSettings={gridSettings}
            imageSettings={brandImageSettings || imageSettings}
            height={height}
        />
    );
    // place logo inside a link if configured to
    const brandLink =
        copySelectedBrand &&
        copySelectedBrand.brandLink &&
        copySelectedBrand.brandLink.linkUrl &&
        copySelectedBrand.brandLink.linkUrl.destinationUrl &&
        copySelectedBrand.brandLink;
    const linkElement = brandLink && (
        <a
            className={'mfrm-brand__link'}
            aria-label={brandLink.ariaLabel}
            href={brandLink.linkUrl && brandLink.linkUrl.destinationUrl}
            target={brandLink.openInNewTab ? '_blank' : '_self'}
            rel='noreferrer'
        >
            {brandLogo}
        </a>
    );

    return linkElement || brandLogo;
};
// get only brandName
export const parseBrandName = (attributes: AttributeValue[] | undefined, backendAttribute: string, recordId?: number): string | undefined => {
    const attribute = backendAttribute || fallBackBrandAttribute;
    let productBrandName = hasArray(attributes)?.find((currAttribute: AttributeValue) => currAttribute?.Name?.toLowerCase().trim() === attribute?.toLowerCase().trim())?.TextValue;
    if (!productBrandName && MsDyn365.isBrowser) {
        const getLocalStorage = localStorage.getItem('bonusItmesWithBrand');
        let bonusItemArray: IProductBonusWithBrandName[] = [];
        if (getLocalStorage) {
            bonusItemArray = JSON.parse(getLocalStorage);
        }
        const checkBrandName = bonusItemArray.find(item => item.productId === recordId)?.brandName;
        if(checkBrandName) {
            productBrandName = checkBrandName;
        } else {
            return undefined;
        }
    }
    return productBrandName;
};

export const parseBrandNameUnbxd = (attributes: any | undefined, backendAttribute: string, recordId?: number): string | undefined => {
    const attribute = backendAttribute || fallBackBrandAttribute;
    // let productBrandName = hasArray(attributes)?.find((currAttribute: AttributeValue) => currAttribute?.Name?.toLowerCase().trim() === attribute?.toLowerCase().trim())?.TextValue;
    let productBrandName = attributes && attributes[attribute];
    if (!productBrandName && MsDyn365.isBrowser) {
        const getLocalStorage = localStorage.getItem('bonusItmesWithBrand');
        let bonusItemArray: IProductBonusWithBrandName[] = [];
        if (getLocalStorage) {
            bonusItemArray = JSON.parse(getLocalStorage);
        }
        const checkBrandName = bonusItemArray.find(item => item.productId === recordId)?.brandName;
        if(checkBrandName) {
            productBrandName = checkBrandName;
        } else {
            return undefined;
        }
    }
    return productBrandName;
};

// get only brandName
export const getBrandNameCheck = (attributes: AttributeValue[] | undefined, backendAttribute: string): string | undefined => {
    const productBrandName =
        attributes &&
        attributes.find(
            currAttribute => currAttribute.Name && currAttribute.Name.toLowerCase().trim() === backendAttribute.toLowerCase().trim()
        )?.TextValue;
    if (!productBrandName) {
        return undefined;
    }
    return productBrandName;
};

// get the appropriate image alt text
const _useDefaultAltText = (selectedBrand: IBrandListData): boolean => {
    const configuredAltText = selectedBrand.brandLogo?.altText;
    return !(configuredAltText && configuredAltText.trim() && configuredAltText !== 'No Data Available');
};
