import * as Msdyn365 from '@msdyn365-commerce/core';

/**
 *  CheckoutSkeleton Input Action
 */
export class CheckoutSkeletonInput implements Msdyn365.IActionInput {
    constructor() {}

    public getCacheKey = () => `CheckoutSkeleton`;
    public getCacheObjectType = () => 'CheckoutSkeleton';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

export interface ICheckoutSkeletonData {
    showPaymentSkeleton: boolean | undefined;
    expressSkeleton: boolean;
    expressPhoneNotavailable: boolean;
    expressLoaderRunning: boolean;
    showDeliverySkeleton: boolean;
    showCustomerInfoSkeleton: boolean;
    isPaymentApiFailed: boolean;
}

const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new CheckoutSkeletonInput();
};


async function action(input: CheckoutSkeletonInput, ctx: Msdyn365.IActionContext): Promise<ICheckoutSkeletonData> {
    return { showPaymentSkeleton: true, expressSkeleton: true, expressPhoneNotavailable: true, expressLoaderRunning: true, showDeliverySkeleton: true, showCustomerInfoSkeleton: true, isPaymentApiFailed: false };
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<ICheckoutSkeletonData>>action,
    id: 'CheckoutSkeleton',
    input: createInput
});
