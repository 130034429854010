import { IActionContext, IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { addCartLinesAsync, removeCartLinesAsync, updateCartLinesAsync, updateLineDeliverySpecificationsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, CartLine, LineDeliverySpecification } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart, ICartState } from '@msdyn365-commerce/global-state';
import { IMFICLDeliveryScheduleParameters, IInventoryStatusResponse } from '../actions/CoreProductDataServiceEntities.g';
import getATPInventoryStatusAction, { GetATPInventoryStatusInput } from '../actions/get-sm-inventory-status.action';// import { IMFIATPInventoryResponse } from '../actions/CoreProductDataServiceEntities.g';
import { eventFullStoryAPISuccess, eventFullStoryFailAPI } from './analytics/fullStoryTrack';
import { addUnitOfMeasureInAddCartLine } from './cart-utils';

export const cartOperationDoRetry = async (callback: () => Promise<ICartActionResultWithCart>, cartState: ICartState) => {
    let callbackResult = await callback();
    if (callbackResult.status === 'SUCCESS') {
        if (callbackResult) {
            await cartState.updateCart({ newCartObject: callbackResult.cart! }).then(null);
        }
    } else {
        callbackResult = await callback();
        if (callbackResult.status === 'SUCCESS') {
            if (callbackResult.cart) {
                void cartState.updateCart({ newCartObject: callbackResult.cart }).then(null);
            }
        }
    }
    return callbackResult?.cart;
};

export const atpInventoryDoRetry = async (callback: () => Promise<IInventoryStatusResponse>) => {
    let callbackResult = await callback();
    if (callbackResult.Message === 'SUCCESS') {
       return callbackResult;
    } else {
        callbackResult = await callback();
        if (callbackResult.Message === 'SUCCESS') {
            return callbackResult;
        }
    }
    return callbackResult;
};

export const updateCartLineDeliverySpecificationsInternal = async (cart: Readonly<Cart | undefined>, cartLineDeliverySpecifications: LineDeliverySpecification[], actionContext: IActionContext, pathName = ""): Promise<ICartActionResultWithCart> => {
    if (!cart || !cart.CartLines || cartLineDeliverySpecifications.length === 0) {
        return { cart: undefined, status: 'FAILED', substatus: 'EMPTYCART' };
    }

    return updateLineDeliverySpecificationsAsync(
        { callerContext: actionContext },
        cart.Id,
        cartLineDeliverySpecifications
    )
        .then(newCart => {
            void eventFullStoryAPISuccess('order_health update_line_delivery_success', pathName);
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
        }).catch(error => {
            void eventFullStoryFailAPI('order_health update_line_delivery_fail', pathName, {error});
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to updateLineDeliverySpecificationsAsync');

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- existing error type.
            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED', errorDetails: { LocalizedMessage: error.message } };
        });
};

export const updateCartLineInternal = async (cart: Readonly<Cart | undefined>, cartLineDeliverySpecifications: LineDeliverySpecification[], actionContext: IActionContext, pathName = ""): Promise<ICartActionResultWithCart> => {
    if (!cart || !cart.CartLines || cartLineDeliverySpecifications.length === 0) {
        return { cart: undefined, status: 'FAILED', substatus: 'EMPTYCART' };
    }

    return updateCartLinesAsync(
        { callerContext: actionContext },
        cart.Id,
        cartLineDeliverySpecifications
    )
        .then(newCart => {
            void eventFullStoryAPISuccess('order_health update_cart_success', pathName);
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
        }).catch(error => {
            void eventFullStoryFailAPI('order_health update_cart_fail', pathName, {error});
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to updateLineDeliverySpecificationsAsync');

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- existing error type.
            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED', errorDetails: { LocalizedMessage: error.message } };
        });
};

export const addCartLineInternal = async (cart: Readonly<Cart | undefined>, cartLineDeliverySpecifications: CartLine[], actionContext: IActionContext, channelId: number): Promise<ICartActionResultWithCart> => {
    if (!cart || !cart.CartLines || cartLineDeliverySpecifications.length === 0) {
        return { cart: undefined, status: 'FAILED', substatus: 'EMPTYCART' };
    }

    return addCartLinesAsync(
        { callerContext: actionContext },
        cart.Id,
        await addUnitOfMeasureInAddCartLine(cartLineDeliverySpecifications, actionContext, channelId)
    )
        .then(newCart => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
        }).catch(error => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to updateLineDeliverySpecificationsAsync');

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- existing error type.
            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED', errorDetails: { LocalizedMessage: error.message } };
        });
};

export default async function removeCartLinesInternal(cart: Readonly<Cart | undefined>, cartLineIds: string[], actionContext: IActionContext): Promise<ICartActionResultWithCart> {
    if (!cart) {
        return { cart: undefined, status: 'FAILED', substatus: 'EMPTYCART' };
    }

    return removeCartLinesAsync({ callerContext: actionContext }, cart.Id, cartLineIds)
        .then(newCart => {
            // This is because of a TSProxy typing error
            const returnedCart = Array.isArray(newCart) ? newCart[0] : newCart;

            return <ICartActionResultWithCart> { cart: returnedCart, status: 'SUCCESS' };
        })
        .catch(error => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to Remove Cart Line');

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- existing error type.
            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED', errorDetails: { LocalizedMessage: error.message } };
        });
}

export const getInventoryQuantityInternal = async(request: IMFICLDeliveryScheduleParameters, context: ICoreContext<IGeneric<IAny>>, isCached?: boolean, pathName = ""): Promise<IInventoryStatusResponse> => {
    if (!request) {
        return <IInventoryStatusResponse>{ Message: 'FAILED' };
    }

    const getATPInventoryStatusInput = new GetATPInventoryStatusInput(request, isCached);
    return getATPInventoryStatusAction(getATPInventoryStatusInput, context.actionContext).then(result => {
        if (result === null || !result.ATPInventoryStatusData) {
            void eventFullStoryFailAPI('order_health get_inventory_status_fail', pathName, { error: { message: 'api given null' } });
            return <IInventoryStatusResponse>{ Message: 'FAILED' };
        }
        void eventFullStoryAPISuccess('order_health get_inventory_status_success', pathName);
        return <IInventoryStatusResponse>{ Message :'SUCCESS', result };
    })
    .catch(error => {
        void eventFullStoryFailAPI('order_health get_inventory_status_fail', pathName, {error});
        context.actionContext.telemetry.warning(error);
        context.actionContext.telemetry.debug('Unable to get ATP Inventory Status');

        return <IInventoryStatusResponse>{ Message: 'FAILED' };
    });
};