import { ICoreContext } from '@msdyn365-commerce/core-internal/dist/types/interfaces';
import { getSetDateGroupedProducts, IMFRMDateGroupedProducts } from '../../../Utilities/cart-utils';
import { hasArray } from '../../../Utilities/emtpyChecker';

export const isOneOfTheCustomMessageStates = (context: ICoreContext, stateId?: string) => {
    const servicesFromConfigurations = context.request.app.config.deliveryServices;
    for (const sc of servicesFromConfigurations) {
        if ((sc.serviceName.includes('silver') || sc.serviceName.includes('Silver')) && sc.multipleMessagesBasedOnState && sc.multipleMessagesBasedOnState.length) {
            for (const stateObj of sc.multipleMessagesBasedOnState) {
                if (stateObj.state === stateId) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const getDeliveryMessageForProduct = (variantID: number) => {
    const dateBasedGroupedProducts: IMFRMDateGroupedProducts[] = getSetDateGroupedProducts('get');
    if(dateBasedGroupedProducts && hasArray(dateBasedGroupedProducts)) {
        for(const dateBasedProduct of dateBasedGroupedProducts) {
            if(hasArray(dateBasedProduct.productVariantRecordIds)) {
                    if(dateBasedProduct.productVariantRecordIds?.includes(variantID)) {
                        let dateStr = dateBasedProduct.dateStr;
                        if(dateStr && dateStr.includes('Delivery by')) {
                            const splitted = dateStr.split('Delivery by ');
                            if(splitted?.length > 1) {
                                dateStr = splitted[1];
                                return dateStr;
                            }
                        }
                        return dateStr;
                    }
            }
        }
    }
    return '';
};