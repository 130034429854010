
  // @ts-ignore -- Unused import
  import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
  import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
  // @ts-ignore -- Unused import
  import { jsonLightReadStringPropertyValue } from '@msdyn365-commerce/retail-proxy/dist/externals';


    /**
    * LeaseLookupByCustomer entity interface.
    */
    export interface ILeaseLookupByCustomer {
    Id: number;
    ExtensionProperties?: Entities.CommerceProperty[];
    }

    /**
    * Store entity interface.
    */
    export interface IStore {
    ProgressiveMerchantId?: number;
    MerchantStoreId?: string;
    SalesAssociate?: string;
    MerchantApplicationIdentifier?: string;
    MerchantTerminalId?: string;
    }

    /**
    * LeaseLookupByCustomerRequest entity interface.
    */
    export interface ILeaseLookupByCustomerRequest {
    Store?: IStore;
    MobilePhoneNumber?: string;
    Last4SSN?: string;
    TenderType?: string;
    TransactionType?: string;
    }

    /**
    * Address entity interface.
    */
    export interface IAddress {
    StreetAddress1?: string;
    StreetAddress2?: string;
    City?: string;
    State?: string;
    Zip?: string;
    }

    /**
    * LeaseLookup entity interface.
    */
    export interface ILeaseLookup {
    ApplicationId?: string;
    Status?: string;
    ApprovalAmount: number;
    IsOkToDeliver: boolean;
    DateOfApplication: Date;
    InvoiceAmount: number;
    MerchantTransactionId?: string;
    FirstName?: string;
    LastName?: string;
    HomePhone?: string;
    CellPhone?: string;
    EmailAddress?: string;
    HomeAddress?: IAddress;
    InitialPaymentSalesTaxAmount: number;
    InitialPaymentPreSalesTax: number;
    InitialPaymentTotal: number;
    PeriodicPaymentTotal: number;
    PayFrequency?: string;
    }

    /**
    * Errors entity interface.
    */
    export interface IErrors {
    }

    /**
    * ProgressiveErrorDetails entity interface.
    */
    export interface IProgressiveErrorDetails {
    Errors?: IErrors;
    Type?: string;
    Title?: string;
    Status: number;
    Detail?: string;
    Instance?: string;
    TraceId?: string;
    ProgressiveErrorCode?: string;
    }

    /**
    * LeaseLookupByCustomerResponse entity interface.
    */
    export interface ILeaseLookupByCustomerResponse {
    LeaseLookup?: ILeaseLookup;
    ProgressiveErrorDetails?: IProgressiveErrorDetails;
    CRTMessage?: string | undefined;
    CRTStatus: boolean;
    IsNoContent: boolean;
    }

    /**
    * PaymentEstimateRequest entity interface.
    */
    export interface IPaymentEstimateRequest {
    InvoiceAmount: number;
    Deposit: number;
    PaymentFrequency?: string;
    State?: string;
    Store?: IStore;
    TenderType?: string;
    TransactionType?: string;
    }

    /**
    * TermMonthsEstimates entity interface.
    */
    export interface ITermMonthsEstimates {
    PaymentMonth: number;
    CostOfLease: number;
    CumulativeAmountPaid: number;
    PaymentNumber: number;
    PaymentAmount: number;
    }

    /**
    * PaymentEstimate entity interface.
    */
    export interface IPaymentEstimate {
    InitialPayment: number;
    TermMonthEstimates?: ITermMonthsEstimates[];
    Message?: string;
    }

    /**
    * PaymentEstimateResponse entity interface.
    */
    export interface IPaymentEstimateResponse {
    PaymentEstimates?: IPaymentEstimate;
    ProgressiveErrorDetails?: IProgressiveErrorDetails;
    CRTMessage?: string;
    CRTStatus: boolean;
    IsNoContent: boolean;
    }

    /**
    * Items entity interface.
    */
    export interface IItems {
    LineNumber: number;
    Sku?: string;
    Model?: string;
    Description?: string;
    Type?: string;
    PriceEach: number;
    LineTotal: number;
    Quantity: number;
    IsNew: boolean;
    }

    /**
    * SubmitCartInformationRequest entity interface.
    */
    export interface ISubmitCartInformationRequest {
    Store?: IStore;
    ApplicationId?: string;
    MerchantTransactionId?: string;
    Deposit: number;
    SalesTax: number;
    CartTotal: number;
    Items?: IItems[];
    TenderType?: string;
    TransactionType?: string;
    }

    /**
    * SubmitCartInformation entity interface.
    */
    export interface ISubmitCartInformation {
    CartId: number;
    Message?: string;
    }

    /**
    * SubmitCartInformationResponse entity interface.
    */
    export interface ISubmitCartInformationResponse {
    SubmitCartInformation?: ISubmitCartInformation;
    ProgressiveErrorDetails?: IProgressiveErrorDetails;
    CRTMessage?: string;
    CRTStatus: boolean;
    IsNoContent: boolean;
    }

    /**
    * GetLeaseContractRequest entity interface.
    */
    export interface IGetLeaseContractRequest {
    ApplicationId?: string;
    TenderType?: string;
    TransactionType?: string;
    }

    /**
    * GetLeaseContract entity interface.
    */
    export interface IGetLeaseContract {
    ESignUrl?: string;
    Message?: string;
    }

    /**
    * GetLeaseContractResponse entity interface.
    */
    export interface IGetLeaseContractResponse {
    LeaseContract?: IGetLeaseContract;
    ProgressiveErrorDetails?: IProgressiveErrorDetails;
    CRTMessage?: string;
    CRTStatus: boolean;
    IsNoContent: boolean;
    }

    /**
    * OrderConfirmationRequest entity interface.
    */
    export interface IOrderConfirmationRequest {
    Store?: IStore;
    ApplicationId?: string;
    CartId: number;
    TenderType?: string;
    TransactionType?: string;
    }

    /**
    * OrderConfirmation entity interface.
    */
    export interface IOrderConfirmation {
    StatusCode: number;
    }

    /**
    * OrderConfirmationResponse entity interface.
    */
    export interface IOrderConfirmationResponse {
    OrderConfirmation?: IOrderConfirmation;
    ProgressiveErrorDetails?: IProgressiveErrorDetails;
    CRTMessage?: string;
    CRTStatus: boolean;
    IsNoContent: boolean;
    }

    /**
    * DeleteTransactionRequest entity interface.
    */
    export interface IDeleteTransactionRequest {
    ApplicationId: number;
    TenderType?: string;
    TransactionType?: string;
    }

    /**
    * DeleteTransaction entity interface.
    */
    export interface IDeleteTransaction {
    }

    /**
    * DeleteTransactionResponse entity interface.
    */
    export interface IDeleteTransactionResponse {
    Transaction?: IDeleteTransaction;
    ProgressiveErrorDetails?: IProgressiveErrorDetails;
    CRTMessage?: string;
    CRTStatus: boolean;
    IsNoContent: boolean;
    }

    /**
    * GetPricingInformationRequet entity interface.
    */
    export interface IGetPricingInformationRequet {
    ApplicationId?: string;
    TenderType?: string;
    TransactionType?: string;
    }

    /**
    * NinethDayPricing entity interface.
    */
    export interface INinethDayPricing {
    EstimatedTax: number;
    LeaseCostToOwn: number;
    NinetyDayBuyout: number;
    LeaseToOwnTotal: number;
    NinetyDayBuyoutMarkup: number;
    NinetyDayBuyoutNetRld: number;
    }

    /**
    * PricingTerms entity interface.
    */
    export interface IPricingTerms {
    InvoiceAmount: number;
    EstimatedTax: number;
    SubTotal: number;
    FullTermTotalCostWithEstimatedTaxNoDeposit: number;
    LastPayment: number;
    LastPaymentEstimatedTax: number;
    TotalLastPayment: number;
    DepositTax: number;
    TotalDeposit: number;
    NetDeposit: number;
    LeaseCostToOwn: number;
    LeaseCostToOwnWithEstimatedTax: number;
    LeaseToOwnTotal: number;
    PeriodicPayment: number;
    RecurringPaymentEstimatedTax: number;
    TotalRecurringPayment: number;
    }

    /**
    * PricingBase entity interface.
    */
    export interface IPricingBase {
    ContractualStartingBalance: number;
    EffectiveLeaseBalance: number;
    RemainingInvoice: number;
    CashPrice: number;
    CashPriceMarkup: number;
    InitialBalance: number;
    InitialCashPrice: number;
    TotalLeasePrice: number;
    LeaseTermId: number;
    PaymentFrequencyId: number;
    TermMonths: number;
    TotalNumberOfPayments: number;
    NumberOfRecurringPayments: number;
    RldCashPrice: number;
    TotalNetRld: number;
    RldCashPriceMarkup: number;
    SalesTaxRate: number;
    LeaseStatus?: string;
    ApprovalLimit: number;
    }

    /**
    * RetailerFees entity interface.
    */
    export interface IRetailerFees {
    MerchantTransactionTypeName?: string;
    TransactionTaxAmount: number;
    MerchantTransactionTypeId: number;
    TransactionAmount: number;
    FeeRuleId: number;
    }

    /**
    * PaymentDueAtSigning entity interface.
    */
    export interface IPaymentDueAtSigning {
    TotalTax: number;
    TotalDueAtSigning: number;
    TotalRetailerFees: number;
    RetailerFeeTax: number;
    RetailerDeliveryFeesTax: number;
    RetailerOtherFeesTax: number;
    TotalRetailerFeeAmount: number;
    InitialPaymentTax: number;
    InitialPayment: number;
    TotalInitialPayment: number;
    TotalRetailerDeliveryFees: number;
    TotalRetailerOtherFees: number;
    RetailerFees?: IRetailerFees[];
    }

    /**
    * GetPricing entity interface.
    */
    export interface IGetPricing {
    NinetyDay?: INinethDayPricing;
    Term?: IPricingTerms;
    Base?: IPricingBase;
    PaymentDueAtSigning?: IPaymentDueAtSigning;
    }

    /**
    * GetPricingInformationResponse entity interface.
    */
    export interface IGetPricingInformationResponse {
    PricingInformation?: IGetPricing;
    ProgressiveErrorDetails?: IProgressiveErrorDetails;
    CRTMessage?: string;
    CRTStatus: boolean;
    IsNoContent: boolean;
    }

    /**
    * MFILeaseLookupByCustomerResponse entity interface.
    */
    export interface IMFILeaseLookupByCustomerResponse {
    LeaseLookupByCustomer?: ILeaseLookupByCustomerResponse;
    }

    /**
    * MFIGetPaymentEstimateResponse entity interface.
    */
    export interface IMFIGetPaymentEstimateResponse {
    PaymentEstimates?: IPaymentEstimateResponse;
    }

    /**
    * MFISubmitCartInformationResponse entity interface.
    */
    export interface IMFISubmitCartInformationResponse {
    SubmitCartInformation?: ISubmitCartInformationResponse;
    }

    /**
    * MFIGetLeaseContractResponse entity interface.
    */
    export interface IMFIGetLeaseContractResponse {
    LeaseContacts?: IGetLeaseContractResponse;
    }

    /**
    * MFIOrderConfirmationResponse entity interface.
    */
    export interface IMFIOrderConfirmationResponse {
    OrderConfirmation?: IOrderConfirmationResponse;
    }

    /**
    * MFIDeleteTransactionResponse entity interface.
    */
    export interface IMFIDeleteTransactionResponse {
    Transaction?: IDeleteTransactionResponse;
    }

    /**
    * MFIGetPricingInformationResponse entity interface.
    */
    export interface IMFIGetPricingInformationResponse {
    PricingInformation?: IGetPricingInformationResponse;
    }

  /**
   * LeaseLookupByCustomer entity class.
   */
  export class LeaseLookupByCustomerExtensionClass implements ILeaseLookupByCustomer {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Id: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Id = odataObject.Id;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }

      }
  }

  /**
   * Store entity class.
   */
  export class StoreExtensionClass implements IStore {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveMerchantId: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MerchantStoreId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SalesAssociate: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MerchantApplicationIdentifier: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MerchantTerminalId: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ProgressiveMerchantId = (odataObject.ProgressiveMerchantId) ? parseInt(odataObject.ProgressiveMerchantId, 10) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MerchantStoreId = odataObject.MerchantStoreId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SalesAssociate = odataObject.SalesAssociate;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MerchantApplicationIdentifier = odataObject.MerchantApplicationIdentifier;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MerchantTerminalId = odataObject.MerchantTerminalId;

      }
  }

  /**
   * LeaseLookupByCustomerRequest entity class.
   */
  export class LeaseLookupByCustomerRequestExtensionClass implements ILeaseLookupByCustomerRequest {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Store:
            IStore;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MobilePhoneNumber: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Last4SSN: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TenderType: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionType: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Store === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Store = undefined;
        } else if (odataObject.Store['@odata.type'] === null) {
          this.Store = new StoreExtensionClass(odataObject.Store);
        } else {
          var className: string = odataObject.Store['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Store = new EntityClasses[className](odataObject.Store);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MobilePhoneNumber = odataObject.MobilePhoneNumber;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Last4SSN = odataObject.Last4SSN;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TenderType = odataObject.TenderType;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionType = odataObject.TransactionType;

      }
  }

  /**
   * Address entity class.
   */
  export class AddressExtensionClass implements IAddress {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StreetAddress1: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StreetAddress2: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public City: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public State: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Zip: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.StreetAddress1 = odataObject.StreetAddress1;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.StreetAddress2 = odataObject.StreetAddress2;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.City = odataObject.City;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.State = odataObject.State;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Zip = odataObject.Zip;

      }
  }

  /**
   * LeaseLookup entity class.
   */
  export class LeaseLookupExtensionClass implements ILeaseLookup {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApplicationId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Status: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApprovalAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsOkToDeliver: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DateOfApplication: Date;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InvoiceAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MerchantTransactionId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FirstName: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LastName: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HomePhone: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CellPhone: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public EmailAddress: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public HomeAddress:
            IAddress;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialPaymentSalesTaxAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialPaymentPreSalesTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialPaymentTotal: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PeriodicPaymentTotal: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PayFrequency: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApplicationId = odataObject.ApplicationId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Status = odataObject.Status;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApprovalAmount = (odataObject.ApprovalAmount) ? parseFloat(odataObject.ApprovalAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsOkToDeliver = odataObject.IsOkToDeliver;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DateOfApplication = (odataObject.DateOfApplication instanceof Date) ? odataObject.DateOfApplication
                : (odataObject.DateOfApplication) ? jsonLightReadStringPropertyValue(odataObject.DateOfApplication, 'Edm.DateTimeOffset', false)  : undefined;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InvoiceAmount = (odataObject.InvoiceAmount) ? parseFloat(odataObject.InvoiceAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MerchantTransactionId = odataObject.MerchantTransactionId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FirstName = odataObject.FirstName;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LastName = odataObject.LastName;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.HomePhone = odataObject.HomePhone;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CellPhone = odataObject.CellPhone;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.EmailAddress = odataObject.EmailAddress;

        if (odataObject.HomeAddress === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.HomeAddress = undefined;
        } else if (odataObject.HomeAddress['@odata.type'] === undefined) {
          this.HomeAddress = new AddressExtensionClass(odataObject.HomeAddress);
        } else {
          var className: string = odataObject.HomeAddress['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.HomeAddress = new EntityClasses[className](odataObject.HomeAddress);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialPaymentSalesTaxAmount = (odataObject.InitialPaymentSalesTaxAmount) ? parseFloat(odataObject.InitialPaymentSalesTaxAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialPaymentPreSalesTax = (odataObject.InitialPaymentPreSalesTax) ? parseFloat(odataObject.InitialPaymentPreSalesTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialPaymentTotal = (odataObject.InitialPaymentTotal) ? parseFloat(odataObject.InitialPaymentTotal) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PeriodicPaymentTotal = (odataObject.PeriodicPaymentTotal) ? parseFloat(odataObject.PeriodicPaymentTotal) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PayFrequency = odataObject.PayFrequency;

      }
  }

  /**
   * Errors entity class.
   */
  // eslint-disable-next-line @typescript-eslint/no-extraneous-class
  export class ErrorsExtensionClass implements IErrors {

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
  }

  /**
   * ProgressiveErrorDetails entity class.
   */
  export class ProgressiveErrorDetailsExtensionClass implements IProgressiveErrorDetails {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Errors:
            IErrors;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Type: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Title: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Status: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Detail: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Instance: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TraceId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorCode: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Errors === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Errors = undefined;
        } else if (odataObject.Errors['@odata.type'] === undefined) {
          this.Errors = new ErrorsExtensionClass(odataObject.Errors);
        } else {
          var className: string = odataObject.Errors['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Errors = new EntityClasses[className](odataObject.Errors);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Type = odataObject.Type;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Title = odataObject.Title;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Status = odataObject.Status;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Detail = odataObject.Detail;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Instance = odataObject.Instance;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TraceId = odataObject.TraceId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ProgressiveErrorCode = odataObject.ProgressiveErrorCode;

      }
  }

  /**
   * LeaseLookupByCustomerResponse entity class.
   */
  export class LeaseLookupByCustomerResponseExtensionClass implements ILeaseLookupByCustomerResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseLookup:
            ILeaseLookup;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorDetails:
            IProgressiveErrorDetails;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTMessage: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTStatus: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNoContent: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.LeaseLookup === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.LeaseLookup = undefined;
        } else if (odataObject.LeaseLookup['@odata.type'] === undefined) {
          this.LeaseLookup = new LeaseLookupExtensionClass(odataObject.LeaseLookup);
        } else {
          var className: string = odataObject.LeaseLookup['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.LeaseLookup = new EntityClasses[className](odataObject.LeaseLookup);
          }
        }


        if (odataObject.ProgressiveErrorDetails === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProgressiveErrorDetails = undefined;
        } else if (odataObject.ProgressiveErrorDetails['@odata.type'] === undefined) {
          this.ProgressiveErrorDetails = new ProgressiveErrorDetailsExtensionClass(odataObject.ProgressiveErrorDetails);
        } else {
          var className: string = odataObject.ProgressiveErrorDetails['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProgressiveErrorDetails = new EntityClasses[className](odataObject.ProgressiveErrorDetails);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTMessage = odataObject.CRTMessage;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTStatus = odataObject.CRTStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNoContent = odataObject.IsNoContent;

      }
  }

  /**
   * PaymentEstimateRequest entity class.
   */
  export class PaymentEstimateRequestExtensionClass implements IPaymentEstimateRequest {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InvoiceAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Deposit: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentFrequency: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public State: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Store:
            IStore;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TenderType: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionType: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InvoiceAmount = (odataObject.InvoiceAmount) ? parseFloat(odataObject.InvoiceAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Deposit = odataObject.Deposit;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PaymentFrequency = odataObject.PaymentFrequency;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.State = odataObject.State;

        if (odataObject.Store === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Store = undefined;
        } else if (odataObject.Store['@odata.type'] === null) {
          this.Store = new StoreExtensionClass(odataObject.Store);
        } else {
          var className: string = odataObject.Store['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Store = new EntityClasses[className](odataObject.Store);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TenderType = odataObject.TenderType;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionType = odataObject.TransactionType;

      }
  }

  /**
   * TermMonthsEstimates entity class.
   */
  export class TermMonthsEstimatesExtensionClass implements ITermMonthsEstimates {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentMonth: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CostOfLease: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CumulativeAmountPaid: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentNumber: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentAmount: number;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PaymentMonth = odataObject.PaymentMonth;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CostOfLease = (odataObject.CostOfLease) ? parseFloat(odataObject.CostOfLease) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CumulativeAmountPaid = (odataObject.CumulativeAmountPaid) ? parseFloat(odataObject.CumulativeAmountPaid) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PaymentNumber = odataObject.PaymentNumber;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PaymentAmount = (odataObject.PaymentAmount) ? parseFloat(odataObject.PaymentAmount) : 0;

      }
  }

  /**
   * PaymentEstimate entity class.
   */
  export class PaymentEstimateExtensionClass implements IPaymentEstimate {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialPayment: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TermMonthEstimates:
            ITermMonthsEstimates[];

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Message: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialPayment = (odataObject.InitialPayment) ? parseFloat(odataObject.InitialPayment) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.TermMonthEstimates = undefined;
        if (odataObject.TermMonthEstimates) {
          this.TermMonthEstimates = [];
          for (var i = 0; i < odataObject.TermMonthEstimates.length; i++) {
            if (odataObject.TermMonthEstimates[i]) {
        if (odataObject.TermMonthEstimates[i]['@odata.type']) {
          var className: string = odataObject.TermMonthEstimates[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.TermMonthEstimates[i] = new EntityClasses[className](odataObject.TermMonthEstimates[i]);
          }
        } else {
          this.TermMonthEstimates[i] = new TermMonthsEstimatesExtensionClass(odataObject.TermMonthEstimates[i]);
        }

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.TermMonthEstimates[i] = undefined;
            }
          }
        }

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Message = odataObject.Message;

      }
  }

  /**
   * PaymentEstimateResponse entity class.
   */
  export class PaymentEstimateResponseExtensionClass implements IPaymentEstimateResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentEstimates:
            IPaymentEstimate;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorDetails:
            IProgressiveErrorDetails;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTMessage: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTStatus: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNoContent: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.PaymentEstimates === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.PaymentEstimates = undefined;
        } else if (odataObject.PaymentEstimates['@odata.type'] === undefined) {
          this.PaymentEstimates = new PaymentEstimateExtensionClass(odataObject.PaymentEstimates);
        } else {
          var className: string = odataObject.PaymentEstimates['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PaymentEstimates = new EntityClasses[className](odataObject.PaymentEstimates);
          }
        }


        if (odataObject.ProgressiveErrorDetails === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProgressiveErrorDetails = undefined;
        } else if (odataObject.ProgressiveErrorDetails['@odata.type'] === undefined) {
          this.ProgressiveErrorDetails = new ProgressiveErrorDetailsExtensionClass(odataObject.ProgressiveErrorDetails);
        } else {
          var className: string = odataObject.ProgressiveErrorDetails['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProgressiveErrorDetails = new EntityClasses[className](odataObject.ProgressiveErrorDetails);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTMessage = odataObject.CRTMessage;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTStatus = odataObject.CRTStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNoContent = odataObject.IsNoContent;

      }
  }

  /**
   * Items entity class.
   */
  export class ItemsExtensionClass implements IItems {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LineNumber: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Sku: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Model: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Description: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Type: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PriceEach: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LineTotal: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNew: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LineNumber = odataObject.LineNumber;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Sku = odataObject.Sku;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Model = odataObject.Model;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Description = odataObject.Description;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Type = odataObject.Type;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PriceEach = (odataObject.PriceEach) ? parseFloat(odataObject.PriceEach) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LineTotal = (odataObject.LineTotal) ? parseFloat(odataObject.LineTotal) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = odataObject.Quantity;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNew = odataObject.IsNew;

      }
  }

  /**
   * SubmitCartInformationRequest entity class.
   */
  export class SubmitCartInformationRequestExtensionClass implements ISubmitCartInformationRequest {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Store:
            IStore;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApplicationId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MerchantTransactionId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Deposit: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SalesTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartTotal: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Items:
            IItems[];

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TenderType: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionType: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Store === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Store = undefined;
        } else if (odataObject.Store['@odata.type'] === null) {
          this.Store = new StoreExtensionClass(odataObject.Store);
        } else {
          var className: string = odataObject.Store['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Store = new EntityClasses[className](odataObject.Store);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApplicationId = odataObject.ApplicationId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MerchantTransactionId = odataObject.MerchantTransactionId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Deposit = (odataObject.Deposit) ? parseFloat(odataObject.Deposit) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SalesTax = (odataObject.SalesTax) ? parseFloat(odataObject.SalesTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CartTotal = (odataObject.CartTotal) ? parseFloat(odataObject.CartTotal) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Items = undefined;
        if (odataObject.Items) {
          this.Items = [];
          for (var i = 0; i < odataObject.Items.length; i++) {
            if (odataObject.Items[i]) {
        if (odataObject.Items[i]['@odata.type']) {
          var className: string = odataObject.Items[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Items[i] = new EntityClasses[className](odataObject.Items[i]);
          }
        } else {
          this.Items[i] = new ItemsExtensionClass(odataObject.Items[i]);
        }

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.Items[i] = undefined;
            }
          }
        }

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TenderType = odataObject.TenderType;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionType = odataObject.TransactionType;

      }
  }

  /**
   * SubmitCartInformation entity class.
   */
  export class SubmitCartInformationExtensionClass implements ISubmitCartInformation {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartId: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Message: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CartId = (odataObject.CartId) ? parseInt(odataObject.CartId, 10) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Message = odataObject.Message;

      }
  }

  /**
   * SubmitCartInformationResponse entity class.
   */
  export class SubmitCartInformationResponseExtensionClass implements ISubmitCartInformationResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SubmitCartInformation:
            ISubmitCartInformation;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorDetails:
            IProgressiveErrorDetails;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTMessage: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTStatus: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNoContent: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.SubmitCartInformation === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.SubmitCartInformation = undefined;
        } else if (odataObject.SubmitCartInformation['@odata.type'] === undefined) {
          this.SubmitCartInformation = new SubmitCartInformationExtensionClass(odataObject.SubmitCartInformation);
        } else {
          var className: string = odataObject.SubmitCartInformation['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.SubmitCartInformation = new EntityClasses[className](odataObject.SubmitCartInformation);
          }
        }


        if (odataObject.ProgressiveErrorDetails === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProgressiveErrorDetails = undefined;
        } else if (odataObject.ProgressiveErrorDetails['@odata.type'] === undefined) {
          this.ProgressiveErrorDetails = new ProgressiveErrorDetailsExtensionClass(odataObject.ProgressiveErrorDetails);
        } else {
          var className: string = odataObject.ProgressiveErrorDetails['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProgressiveErrorDetails = new EntityClasses[className](odataObject.ProgressiveErrorDetails);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTMessage = odataObject.CRTMessage;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTStatus = odataObject.CRTStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNoContent = odataObject.IsNoContent;

      }
  }

  /**
   * GetLeaseContractRequest entity class.
   */
  export class GetLeaseContractRequestExtensionClass implements IGetLeaseContractRequest {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApplicationId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TenderType: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionType: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApplicationId = odataObject.ApplicationId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TenderType = odataObject.TenderType;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionType = odataObject.TransactionType;

      }
  }

  /**
   * GetLeaseContract entity class.
   */
  export class GetLeaseContractExtensionClass implements IGetLeaseContract {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ESignUrl: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Message: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ESignUrl = odataObject.ESignUrl;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Message = odataObject.Message;

      }
  }

  /**
   * GetLeaseContractResponse entity class.
   */
  export class GetLeaseContractResponseExtensionClass implements IGetLeaseContractResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseContract:
            IGetLeaseContract;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorDetails:
            IProgressiveErrorDetails;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTMessage: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTStatus: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNoContent: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.LeaseContract === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.LeaseContract = undefined;
        } else if (odataObject.LeaseContract['@odata.type'] === undefined) {
          this.LeaseContract = new GetLeaseContractExtensionClass(odataObject.LeaseContract);
        } else {
          var className: string = odataObject.LeaseContract['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.LeaseContract = new EntityClasses[className](odataObject.LeaseContract);
          }
        }


        if (odataObject.ProgressiveErrorDetails === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProgressiveErrorDetails = undefined;
        } else if (odataObject.ProgressiveErrorDetails['@odata.type'] === undefined) {
          this.ProgressiveErrorDetails = new ProgressiveErrorDetailsExtensionClass(odataObject.ProgressiveErrorDetails);
        } else {
          var className: string = odataObject.ProgressiveErrorDetails['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProgressiveErrorDetails = new EntityClasses[className](odataObject.ProgressiveErrorDetails);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTMessage = odataObject.CRTMessage;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTStatus = odataObject.CRTStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNoContent = odataObject.IsNoContent;

      }
  }

  /**
   * OrderConfirmationRequest entity class.
   */
  export class OrderConfirmationRequestExtensionClass implements IOrderConfirmationRequest {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Store:
            IStore;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApplicationId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CartId: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TenderType: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionType: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Store === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Store = undefined;
        } else if (odataObject.Store['@odata.type'] === null) {
          this.Store = new StoreExtensionClass(odataObject.Store);
        } else {
          var className: string = odataObject.Store['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Store = new EntityClasses[className](odataObject.Store);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApplicationId = odataObject.ApplicationId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CartId = (odataObject.CartId) ? parseInt(odataObject.CartId, 10) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TenderType = odataObject.TenderType;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionType = odataObject.TransactionType;

      }
  }

  /**
   * OrderConfirmation entity class.
   */
  export class OrderConfirmationExtensionClass implements IOrderConfirmation {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StatusCode: number;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.StatusCode = odataObject.StatusCode;

      }
  }

  /**
   * OrderConfirmationResponse entity class.
   */
  export class OrderConfirmationResponseExtensionClass implements IOrderConfirmationResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OrderConfirmation:
            IOrderConfirmation;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorDetails:
            IProgressiveErrorDetails;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTMessage: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTStatus: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNoContent: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.OrderConfirmation === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OrderConfirmation = undefined;
        } else if (odataObject.OrderConfirmation['@odata.type'] === null) {
          this.OrderConfirmation = new OrderConfirmationExtensionClass(odataObject.OrderConfirmation);
        } else {
          var className: string = odataObject.OrderConfirmation['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.OrderConfirmation = new EntityClasses[className](odataObject.OrderConfirmation);
          }
        }


        if (odataObject.ProgressiveErrorDetails === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProgressiveErrorDetails = undefined;
        } else if (odataObject.ProgressiveErrorDetails['@odata.type'] === null) {
          this.ProgressiveErrorDetails = new ProgressiveErrorDetailsExtensionClass(odataObject.ProgressiveErrorDetails);
        } else {
          var className: string = odataObject.ProgressiveErrorDetails['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProgressiveErrorDetails = new EntityClasses[className](odataObject.ProgressiveErrorDetails);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTMessage = odataObject.CRTMessage;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTStatus = odataObject.CRTStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNoContent = odataObject.IsNoContent;

      }
  }

  /**
   * DeleteTransactionRequest entity class.
   */
  export class DeleteTransactionRequestExtensionClass implements IDeleteTransactionRequest {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApplicationId: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TenderType: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionType: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApplicationId = (odataObject.ApplicationId) ? parseInt(odataObject.ApplicationId, 10) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TenderType = odataObject.TenderType;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionType = odataObject.TransactionType;

      }
  }

  /**
   * DeleteTransaction entity class.
   */
  // eslint-disable-next-line @typescript-eslint/no-extraneous-class
  export class DeleteTransactionExtensionClass implements IDeleteTransaction {

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
      }
  }

  /**
   * DeleteTransactionResponse entity class.
   */
  export class DeleteTransactionResponseExtensionClass implements IDeleteTransactionResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Transaction:
            IDeleteTransaction;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorDetails:
            IProgressiveErrorDetails;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTMessage: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTStatus: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNoContent: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Transaction === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Transaction = undefined;
        } else if (odataObject.Transaction['@odata.type'] === undefined) {
          this.Transaction = new DeleteTransactionExtensionClass(odataObject.Transaction);
        } else {
          var className: string = odataObject.Transaction['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Transaction = new EntityClasses[className](odataObject.Transaction);
          }
        }


        if (odataObject.ProgressiveErrorDetails === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProgressiveErrorDetails = undefined;
        } else if (odataObject.ProgressiveErrorDetails['@odata.type'] === undefined) {
          this.ProgressiveErrorDetails = new ProgressiveErrorDetailsExtensionClass(odataObject.ProgressiveErrorDetails);
        } else {
          var className: string = odataObject.ProgressiveErrorDetails['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProgressiveErrorDetails = new EntityClasses[className](odataObject.ProgressiveErrorDetails);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTMessage = odataObject.CRTMessage;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTStatus = odataObject.CRTStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNoContent = odataObject.IsNoContent;

      }
  }

  /**
   * GetPricingInformationRequet entity class.
   */
  export class GetPricingInformationRequetExtensionClass implements IGetPricingInformationRequet {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApplicationId: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TenderType: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionType: string;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApplicationId = odataObject.ApplicationId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TenderType = odataObject.TenderType;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionType = odataObject.TransactionType;

      }
  }

  /**
   * NinethDayPricing entity class.
   */
  export class NinethDayPricingExtensionClass implements INinethDayPricing {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public EstimatedTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseCostToOwn: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NinetyDayBuyout: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseToOwnTotal: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NinetyDayBuyoutMarkup: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NinetyDayBuyoutNetRld: number;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.EstimatedTax = (odataObject.EstimatedTax) ? parseFloat(odataObject.EstimatedTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeaseCostToOwn = (odataObject.LeaseCostToOwn) ? parseFloat(odataObject.LeaseCostToOwn) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NinetyDayBuyout = (odataObject.NinetyDayBuyout) ? parseFloat(odataObject.NinetyDayBuyout) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeaseToOwnTotal = (odataObject.LeaseToOwnTotal) ? parseFloat(odataObject.LeaseToOwnTotal) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NinetyDayBuyoutMarkup = (odataObject.NinetyDayBuyoutMarkup) ? parseFloat(odataObject.NinetyDayBuyoutMarkup) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NinetyDayBuyoutNetRld = (odataObject.NinetyDayBuyoutNetRld) ? parseFloat(odataObject.NinetyDayBuyoutNetRld) : 0;

      }
  }

  /**
   * PricingTerms entity class.
   */
  export class PricingTermsExtensionClass implements IPricingTerms {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InvoiceAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public EstimatedTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SubTotal: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FullTermTotalCostWithEstimatedTaxNoDeposit: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LastPayment: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LastPaymentEstimatedTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalLastPayment: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DepositTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalDeposit: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NetDeposit: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseCostToOwn: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseCostToOwnWithEstimatedTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseToOwnTotal: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PeriodicPayment: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RecurringPaymentEstimatedTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalRecurringPayment: number;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InvoiceAmount = (odataObject.InvoiceAmount) ? parseFloat(odataObject.InvoiceAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.EstimatedTax = (odataObject.EstimatedTax) ? parseFloat(odataObject.EstimatedTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SubTotal = (odataObject.SubTotal) ? parseFloat(odataObject.SubTotal) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FullTermTotalCostWithEstimatedTaxNoDeposit = (odataObject.FullTermTotalCostWithEstimatedTaxNoDeposit) ? parseFloat(odataObject.FullTermTotalCostWithEstimatedTaxNoDeposit) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LastPayment = (odataObject.LastPayment) ? parseFloat(odataObject.LastPayment) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LastPaymentEstimatedTax = (odataObject.LastPaymentEstimatedTax) ? parseFloat(odataObject.LastPaymentEstimatedTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalLastPayment = (odataObject.TotalLastPayment) ? parseFloat(odataObject.TotalLastPayment) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DepositTax = (odataObject.DepositTax) ? parseFloat(odataObject.DepositTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalDeposit = (odataObject.TotalDeposit) ? parseFloat(odataObject.TotalDeposit) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NetDeposit = (odataObject.NetDeposit) ? parseFloat(odataObject.NetDeposit) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeaseCostToOwn = (odataObject.LeaseCostToOwn) ? parseFloat(odataObject.LeaseCostToOwn) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeaseCostToOwnWithEstimatedTax = (odataObject.LeaseCostToOwnWithEstimatedTax) ? parseFloat(odataObject.LeaseCostToOwnWithEstimatedTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeaseToOwnTotal = (odataObject.LeaseToOwnTotal) ? parseFloat(odataObject.LeaseToOwnTotal) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PeriodicPayment = (odataObject.PeriodicPayment) ? parseFloat(odataObject.PeriodicPayment) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RecurringPaymentEstimatedTax = (odataObject.RecurringPaymentEstimatedTax) ? parseFloat(odataObject.RecurringPaymentEstimatedTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalRecurringPayment = (odataObject.TotalRecurringPayment) ? parseFloat(odataObject.TotalRecurringPayment) : 0;

      }
  }

  /**
   * PricingBase entity class.
   */
  export class PricingBaseExtensionClass implements IPricingBase {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ContractualStartingBalance: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public EffectiveLeaseBalance: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RemainingInvoice: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CashPrice: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CashPriceMarkup: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialBalance: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialCashPrice: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalLeasePrice: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseTermId: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentFrequencyId: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TermMonths: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalNumberOfPayments: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NumberOfRecurringPayments: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RldCashPrice: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalNetRld: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RldCashPriceMarkup: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SalesTaxRate: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseStatus: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ApprovalLimit: number;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ContractualStartingBalance = (odataObject.ContractualStartingBalance) ? parseFloat(odataObject.ContractualStartingBalance) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.EffectiveLeaseBalance = (odataObject.EffectiveLeaseBalance) ? parseFloat(odataObject.EffectiveLeaseBalance) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RemainingInvoice = (odataObject.RemainingInvoice) ? parseFloat(odataObject.RemainingInvoice) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CashPrice = (odataObject.CashPrice) ? parseFloat(odataObject.CashPrice) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CashPriceMarkup = (odataObject.CashPriceMarkup) ? parseFloat(odataObject.CashPriceMarkup) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialBalance = (odataObject.InitialBalance) ? parseFloat(odataObject.InitialBalance) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialCashPrice = (odataObject.InitialCashPrice) ? parseFloat(odataObject.InitialCashPrice) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalLeasePrice = (odataObject.TotalLeasePrice) ? parseFloat(odataObject.TotalLeasePrice) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeaseTermId = odataObject.LeaseTermId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.PaymentFrequencyId = odataObject.PaymentFrequencyId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TermMonths = odataObject.TermMonths;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalNumberOfPayments = odataObject.TotalNumberOfPayments;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.NumberOfRecurringPayments = odataObject.NumberOfRecurringPayments;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RldCashPrice = (odataObject.RldCashPrice) ? parseFloat(odataObject.RldCashPrice) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalNetRld = (odataObject.TotalNetRld) ? parseFloat(odataObject.TotalNetRld) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RldCashPriceMarkup = (odataObject.RldCashPriceMarkup) ? parseFloat(odataObject.RldCashPriceMarkup) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SalesTaxRate = (odataObject.SalesTaxRate) ? parseFloat(odataObject.SalesTaxRate) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LeaseStatus = odataObject.LeaseStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ApprovalLimit = (odataObject.ApprovalLimit) ? parseFloat(odataObject.ApprovalLimit) : 0;

      }
  }

  /**
   * RetailerFees entity class.
   */
  export class RetailerFeesExtensionClass implements IRetailerFees {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MerchantTransactionTypeName: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionTaxAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public MerchantTransactionTypeId: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TransactionAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FeeRuleId: number;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MerchantTransactionTypeName = odataObject.MerchantTransactionTypeName;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionTaxAmount = (odataObject.TransactionTaxAmount) ? parseFloat(odataObject.TransactionTaxAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.MerchantTransactionTypeId = odataObject.MerchantTransactionTypeId;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TransactionAmount = (odataObject.TransactionAmount) ? parseFloat(odataObject.TransactionAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FeeRuleId = odataObject.FeeRuleId;

      }
  }

  /**
   * PaymentDueAtSigning entity class.
   */
  export class PaymentDueAtSigningExtensionClass implements IPaymentDueAtSigning {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalDueAtSigning: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalRetailerFees: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailerFeeTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailerDeliveryFeesTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailerOtherFeesTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalRetailerFeeAmount: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialPaymentTax: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InitialPayment: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalInitialPayment: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalRetailerDeliveryFees: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public TotalRetailerOtherFees: number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public RetailerFees:
            IRetailerFees[];

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalTax = (odataObject.TotalTax) ? parseFloat(odataObject.TotalTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalDueAtSigning = (odataObject.TotalDueAtSigning) ? parseFloat(odataObject.TotalDueAtSigning) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalRetailerFees = (odataObject.TotalRetailerFees) ? parseFloat(odataObject.TotalRetailerFees) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RetailerFeeTax = (odataObject.RetailerFeeTax) ? parseFloat(odataObject.RetailerFeeTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RetailerDeliveryFeesTax = (odataObject.RetailerDeliveryFeesTax) ? parseFloat(odataObject.RetailerDeliveryFeesTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.RetailerOtherFeesTax = (odataObject.RetailerOtherFeesTax) ? parseFloat(odataObject.RetailerOtherFeesTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalRetailerFeeAmount = (odataObject.TotalRetailerFeeAmount) ? parseFloat(odataObject.TotalRetailerFeeAmount) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialPaymentTax = (odataObject.InitialPaymentTax) ? parseFloat(odataObject.InitialPaymentTax) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InitialPayment = (odataObject.InitialPayment) ? parseFloat(odataObject.InitialPayment) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalInitialPayment = (odataObject.TotalInitialPayment) ? parseFloat(odataObject.TotalInitialPayment) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalRetailerDeliveryFees = (odataObject.TotalRetailerDeliveryFees) ? parseFloat(odataObject.TotalRetailerDeliveryFees) : 0;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.TotalRetailerOtherFees = (odataObject.TotalRetailerOtherFees) ? parseFloat(odataObject.TotalRetailerOtherFees) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RetailerFees = undefined;
        if (odataObject.RetailerFees) {
          this.RetailerFees = [];
          for (var i = 0; i < odataObject.RetailerFees.length; i++) {
            if (odataObject.RetailerFees[i]) {
        if (odataObject.RetailerFees[i]['@odata.type']) {
          var className: string = odataObject.RetailerFees[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.RetailerFees[i] = new EntityClasses[className](odataObject.RetailerFees[i]);
          }
        } else {
          this.RetailerFees[i] = new RetailerFeesExtensionClass(odataObject.RetailerFees[i]);
        }

            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.RetailerFees[i] = undefined;
            }
          }
        }

      }
  }

  /**
   * GetPricing entity class.
   */
  export class GetPricingExtensionClass implements IGetPricing {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public NinetyDay:
            INinethDayPricing;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Term:
            IPricingTerms;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Base:
            IPricingBase;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentDueAtSigning:
            IPaymentDueAtSigning;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.NinetyDay === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.NinetyDay = undefined;
        } else if (odataObject.NinetyDay['@odata.type'] === undefined) {
          this.NinetyDay = new NinethDayPricingExtensionClass(odataObject.NinetyDay);
        } else {
          var className: string = odataObject.NinetyDay['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.NinetyDay = new EntityClasses[className](odataObject.NinetyDay);
          }
        }


        if (odataObject.Term === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Term = undefined;
        } else if (odataObject.Term['@odata.type'] === undefined) {
          this.Term = new PricingTermsExtensionClass(odataObject.Term);
        } else {
          var className: string = odataObject.Term['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Term = new EntityClasses[className](odataObject.Term);
          }
        }


        if (odataObject.Base === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Base = undefined;
        } else if (odataObject.Base['@odata.type'] === undefined) {
          this.Base = new PricingBaseExtensionClass(odataObject.Base);
        } else {
          var className: string = odataObject.Base['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Base = new EntityClasses[className](odataObject.Base);
          }
        }


        if (odataObject.PaymentDueAtSigning === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.PaymentDueAtSigning = undefined;
        } else if (odataObject.PaymentDueAtSigning['@odata.type'] === undefined) {
          this.PaymentDueAtSigning = new PaymentDueAtSigningExtensionClass(odataObject.PaymentDueAtSigning);
        } else {
          var className: string = odataObject.PaymentDueAtSigning['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PaymentDueAtSigning = new EntityClasses[className](odataObject.PaymentDueAtSigning);
          }
        }


      }
  }

  /**
   * GetPricingInformationResponse entity class.
   */
  export class GetPricingInformationResponseExtensionClass implements IGetPricingInformationResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PricingInformation:
            IGetPricing;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ProgressiveErrorDetails:
            IProgressiveErrorDetails;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTMessage: string;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public CRTStatus: boolean;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsNoContent: boolean;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.PricingInformation === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.PricingInformation = undefined;
        } else if (odataObject.PricingInformation['@odata.type'] === undefined) {
          this.PricingInformation = new GetPricingExtensionClass(odataObject.PricingInformation);
        } else {
          var className: string = odataObject.PricingInformation['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PricingInformation = new EntityClasses[className](odataObject.PricingInformation);
          }
        }


        if (odataObject.ProgressiveErrorDetails === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProgressiveErrorDetails = undefined;
        } else if (odataObject.ProgressiveErrorDetails['@odata.type'] === undefined) {
          this.ProgressiveErrorDetails = new ProgressiveErrorDetailsExtensionClass(odataObject.ProgressiveErrorDetails);
        } else {
          var className: string = odataObject.ProgressiveErrorDetails['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ProgressiveErrorDetails = new EntityClasses[className](odataObject.ProgressiveErrorDetails);
          }
        }


            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTMessage = odataObject.CRTMessage;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.CRTStatus = odataObject.CRTStatus;

            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsNoContent = odataObject.IsNoContent;

      }
  }

  /**
   * MFILeaseLookupByCustomerResponse entity class.
   */
  export class MFILeaseLookupByCustomerResponseExtensionClass implements IMFILeaseLookupByCustomerResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseLookupByCustomer:
            ILeaseLookupByCustomerResponse;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.LeaseLookupByCustomer === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.LeaseLookupByCustomer = undefined;
        } else if (odataObject.LeaseLookupByCustomer['@odata.type'] === undefined) {
          this.LeaseLookupByCustomer = new LeaseLookupByCustomerResponseExtensionClass(odataObject.LeaseLookupByCustomer);
        } else {
          var className: string = odataObject.LeaseLookupByCustomer['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.LeaseLookupByCustomer = new EntityClasses[className](odataObject.LeaseLookupByCustomer);
          }
        }


      }
  }

  /**
   * MFIGetPaymentEstimateResponse entity class.
   */
  export class MFIGetPaymentEstimateResponseExtensionClass implements IMFIGetPaymentEstimateResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PaymentEstimates:
            IPaymentEstimateResponse;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.PaymentEstimates === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.PaymentEstimates = undefined;
        } else if (odataObject.PaymentEstimates['@odata.type'] === undefined) {
          this.PaymentEstimates = new PaymentEstimateResponseExtensionClass(odataObject.PaymentEstimates);
        } else {
          var className: string = odataObject.PaymentEstimates['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PaymentEstimates = new EntityClasses[className](odataObject.PaymentEstimates);
          }
        }


      }
  }

  /**
   * MFISubmitCartInformationResponse entity class.
   */
  export class MFISubmitCartInformationResponseExtensionClass implements IMFISubmitCartInformationResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SubmitCartInformation:
            ISubmitCartInformationResponse;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.SubmitCartInformation === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.SubmitCartInformation = undefined;
        } else if (odataObject.SubmitCartInformation['@odata.type'] === undefined) {
          this.SubmitCartInformation = new SubmitCartInformationResponseExtensionClass(odataObject.SubmitCartInformation);
        } else {
          var className: string = odataObject.SubmitCartInformation['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.SubmitCartInformation = new EntityClasses[className](odataObject.SubmitCartInformation);
          }
        }


      }
  }

  /**
   * MFIGetLeaseContractResponse entity class.
   */
  export class MFIGetLeaseContractResponseExtensionClass implements IMFIGetLeaseContractResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LeaseContacts:
            IGetLeaseContractResponse;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.LeaseContacts === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.LeaseContacts = undefined;
        } else if (odataObject.LeaseContacts['@odata.type'] === undefined) {
          this.LeaseContacts = new GetLeaseContractResponseExtensionClass(odataObject.LeaseContacts);
        } else {
          var className: string = odataObject.LeaseContacts['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.LeaseContacts = new EntityClasses[className](odataObject.LeaseContacts);
          }
        }


      }
  }

  /**
   * MFIOrderConfirmationResponse entity class.
   */
  export class MFIOrderConfirmationResponseExtensionClass implements IMFIOrderConfirmationResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public OrderConfirmation:
            IOrderConfirmationResponse;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.OrderConfirmation === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OrderConfirmation = undefined;
        } else if (odataObject.OrderConfirmation['@odata.type'] === null) {
          this.OrderConfirmation = new OrderConfirmationResponseExtensionClass(odataObject.OrderConfirmation);
        } else {
          var className: string = odataObject.OrderConfirmation['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.OrderConfirmation = new EntityClasses[className](odataObject.OrderConfirmation);
          }
        }


      }
  }

  /**
   * MFIDeleteTransactionResponse entity class.
   */
  export class MFIDeleteTransactionResponseExtensionClass implements IMFIDeleteTransactionResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Transaction:
            IDeleteTransactionResponse;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.Transaction === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Transaction = undefined;
        } else if (odataObject.Transaction['@odata.type'] === undefined) {
          this.Transaction = new DeleteTransactionResponseExtensionClass(odataObject.Transaction);
        } else {
          var className: string = odataObject.Transaction['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Transaction = new EntityClasses[className](odataObject.Transaction);
          }
        }


      }
  }

  /**
   * MFIGetPricingInformationResponse entity class.
   */
  export class MFIGetPricingInformationResponseExtensionClass implements IMFIGetPricingInformationResponse {

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public PricingInformation:
            IGetPricingInformationResponse;

      // Navigation properties names

      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        if (odataObject.PricingInformation === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.PricingInformation = undefined;
        } else if (odataObject.PricingInformation['@odata.type'] === undefined) {
          this.PricingInformation = new GetPricingInformationResponseExtensionClass(odataObject.PricingInformation);
        } else {
          var className: string = odataObject.PricingInformation['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.PricingInformation = new EntityClasses[className](odataObject.PricingInformation);
          }
        }


      }
  }
