import Cookies from 'universal-cookie';

const cookies = new Cookies();

// use this function to get cookie
export function getCookie(cookieName: string): string | undefined {
    return cookies.get(cookieName);
}

// use this function to set cookie
export function setCookie(cookieName: string, cookieValue: any, options?: any): void {
    cookies.set(cookieName, cookieValue, options);
}

// use this function to remove cookie
export function removeCookie(cookieName: string, options?: any): void {
    cookies.remove(cookieName, options);
}