import MsDyn365, { IActionContext, IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { AttributeValue, CartLine } from '@msdyn365-commerce/retail-proxy';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import { IMFIGetBonusCouponsDetailsResponse } from '../../../actions/BonusItemDataServiceEntities.g';
import { IMFICartLine, IMFICLDeliveryScheduleParameters } from '../../../actions/CoreProductDataServiceEntities.g';
import { hasArray } from '../../../Utilities/emtpyChecker';
import _getDeliveryByMessage from '../../../Utilities/get-delivery-by-message';
import { _getSoonestDeliveryDate } from '../../../Utilities/product-search-result-utils';
import { IProductBonusItems } from '../mfrm-cart';
import { IMfrmCartData } from '../mfrm-cart.data';
import { IMfrmCartProps } from '../mfrm-cart.props.autogenerated';
import { IMFIATPInventoryEntityPLP, IMFIATPInventoryPLPResponse } from '../../../actions/BatchCoreProductDataServiceEntities.g';
import fetchCoreDeliveryListAction, { FetchCoreDeliveryListInput } from '../../../actions/fetch-core-delivery-list.action';
import { IMFIATPInventoryEntityPLPEx } from '../../../Utilities/get-core-delivery-batch';
import { DeliveryDateDetails, DeliveryModes } from '../../../Utilities/enum';
import { changePhoneFormatForAPI, validateZipCode } from '../../../Utilities/checkout-utils';
export interface IBasketCookieCartInfo {
    productId: number;
    itemId: string;
    quantity: number;
}

export interface IBasketCookie {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    address1?: string;
    address2?: string;
    cartId?: string;
    selectedDeliveryService?: number;
    EmailMeCheckbox?: boolean;
    cartDataInfo?: IBasketCookieCartInfo[];
}

export interface IProductDetails {
    typeOfProduct?: string;
    productQuantity?: string[];
    quantity?: string;
    subTotal?: string;
}

export interface ICartProductUrl {
    productImageUrl?: string[];
    productURL?: string[];
    cartUrl?: string;
}

export interface IProductDetailsData {
    quantity?: number | undefined;
    subTotal?: number | undefined;
    productQuantity?: string[];
    productSize?: string[];
    productIDsArray?: string[];
    productNameArray?: string[];
    productPriceArray?: string[];
    variantRecord?: (string | undefined)[];
    productCatgoryArray?: (string | undefined)[];
    productBrandName?: (string | undefined)[];
    productDiscountArray?: (string | undefined)[];
    cartProductPriceArray?: string[];
}

export const isOneOfChildBonusCoupons = (discountCode: string, appliedCoupon: string) => {
    if (MsDyn365.isBrowser) {
        const getLocalstorageBonusCoupons = localStorage.getItem('bonusCouponsDetail');
        if (getLocalstorageBonusCoupons) {
            const bonusCouponsArray: IMFIGetBonusCouponsDetailsResponse[] =
                getLocalstorageBonusCoupons && JSON.parse(getLocalstorageBonusCoupons);
            // props.data.cartLine.DiscountLines && props.data.cartLine.DiscountLines.map((userDefinePromo) => {
            for (const bonusCoupons of bonusCouponsArray) {
                // bonusCouponsArray.map((bonusCoupons) => {
                if (bonusCoupons.BonusCoupons) {
                    for (const coupons of bonusCoupons.BonusCoupons) {
                        // bonusCoupons.BonusCoupons?.map((coupons) => {
                        if (coupons.BonusCoupon === appliedCoupon && coupons.ChildCoupons) {
                            for (const childCoupons of coupons.ChildCoupons) {
                                // coupons.ChildCoupons?.map((childCoupons) => {
                                if (childCoupons === discountCode) {
                                    return true;
                                }
                                // });
                            }
                        }
                        // });
                    }
                }
                // });
            }
            // });
        }
    }
    return false;
};
// VSI Customization - START
const getItemLines = (itemId: string | undefined, quantity: number, recordId: number) => {
    const itemLines: IMFICartLine = {
        ItemId: itemId,
        Quantity: quantity,
        VariantRecordId: recordId.toString()
    };
    return itemLines;
};

const _callAtpCallProductAndOOM = async (
    itemId: string | undefined,
    quantity: number,
    recordId: number,
    zipcode: any,
    context: ICoreContext<IGeneric<IAny>>
) => {
    const itemLinesArray = [];
    const itemLines = getItemLines(itemId, quantity, recordId);
    itemLinesArray.push(itemLines);
    const date = new Date();
    const currentDate = date.setDate(date.getDate());
    const requestBody: IMFICLDeliveryScheduleParameters = {
        InventoryType: DeliveryModes.core,
        Weeks: context.app.config.weeksPDPforATPCall,
        StoreId: '',
        Page: 'pdp',
        RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
        ZipCode: zipcode,
        ItemLines: itemLinesArray
    };
    let outOfMarket = '';
    const outOfMarketMsg = context.app.config.responseMessageForItemOutOfMarketZip;
    const msgOutOfMarket = outOfMarketMsg?.slice(0, 38);
    const result = await _getDeliveryByMessage(requestBody, context, false);
    const resultmMSg = result?.Message?.slice(12, 50);
    if (
        msgOutOfMarket === resultmMSg ||
        context.app.config.responseMessageForItemOutOfMarketOOZ
            .slice(0, 31)
            .trim()
            .toLowerCase() ===
            resultmMSg
                ?.slice(0, 31)
                .trim()
                .toLowerCase()
    ) {
        outOfMarket = 'yes';
    }
    const deliveryDetails =
        result?.ATPInventoryData && result?.ATPInventoryData.length > 0 ? _getSoonestDeliveryDate(result?.ATPInventoryData) : undefined;
    let msg = deliveryDetails
        ? deliveryDetails.deliveryByMessage
        : context.app.config.defaultDeliveryMessage
        ? context.app.config.defaultDeliveryMessage
        : '';
    if (msg === undefined || msg === '') {
        msg = context.app.config.defaultDeliveryMessage;
    }
    return `${itemId ? itemId : ''},${recordId.toString()},${msg} ,${outOfMarket},${deliveryDetails?.locationId}`;
};

export const _coreProductATPCall = async (
    props: IMfrmCartProps<IMfrmCartData>,
    recordId: number,
    itemId: string | undefined,
    quantity: number
): Promise<string> => {
    const { context } = props;
    const cookies = new Cookies();
    const zipcode = cookies.get('zip_code');
    return __._callAtpCallProductAndOOM(itemId, quantity, recordId, zipcode, context);
};
// VSI Customization - End

/** Skipping customization for _coreProductATPCallCheckForOOM function as it has a broader scope beyond the cart feature. Instead, I made other changes to ensure the code can be easily converted in the future, in adherence to the Open/Closed Principle.*/

export const _coreProductATPCallCheckForOOM = async (
    context: ICoreContext<IGeneric<IAny>>,
    recordId: number,
    itemId: string | undefined,
    quantity: number,
    updatedZipCode: string
): Promise<string> => {
    const cookies = new Cookies();
    const zipcode = (validateZipCode(updatedZipCode)) ? updatedZipCode : cookies.get('zip_code');
    const itemLinesArray = [];

    const itemLines: IMFICartLine = {
        ItemId: itemId,
        Quantity: quantity,
        VariantRecordId: recordId.toString()
    };

    itemLinesArray.push(itemLines);
    const date = new Date();
    const currentDate = date.setDate(date.getDate());
    const requestBody: IMFICLDeliveryScheduleParameters = {
        InventoryType: DeliveryModes.core,
        Weeks: context.app.config.weeksPDPforATPCall,
        StoreId: '',
        Page: 'pdp',
        RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
        ZipCode: zipcode,
        ItemLines: itemLinesArray
    };
    let outOfMarket = '';
    const outOfMarketMsg = context.app.config.responseMessageForItemOutOfMarketZip;
    const msgOutOfMarket = outOfMarketMsg?.slice(0, 38);
    const result = await _getDeliveryByMessage(requestBody, context, false);
    const resultmMSg = result?.Message?.slice(12, 50);
    if (
        msgOutOfMarket === resultmMSg ||
        context.app.config.responseMessageForItemOutOfMarketOOZ?.slice(0, 31).toLowerCase() === resultmMSg?.slice(0, 31).toLowerCase()
    ) {
        outOfMarket = 'yes';
    }

    const deliveryDetails =
        result?.ATPInventoryData && result?.ATPInventoryData.length > 0 ? _getSoonestDeliveryDate(result?.ATPInventoryData) : undefined;

    let msg = deliveryDetails
        ? deliveryDetails.deliveryByMessage
        : context.app.config.defaultDeliveryMessage
        ? context.app.config.defaultDeliveryMessage
        : '';
    if (msg === undefined || msg === '') {
        msg = context.app.config.defaultDeliveryMessage;
    }
    return `${itemId ? itemId : ''},${recordId.toString()},${msg} ,${outOfMarket},${deliveryDetails?.locationId}`;
};

const findIndexOfItemInArray = (cartLines: CartLine[], prodId: string) => {
    if (cartLines && cartLines.length) {
        for (let c = 0; c < cartLines.length; c++) {
            if (cartLines[c].ProductId?.toString() === prodId) {
                return c;
            }
        }
    }
    return -1;
};
const arraymove = (arr: CartLine[], fromIndex: number, toIndex: number) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
};
export const reshuffleItems = (cartLines: CartLine[]) => {
    const cls: CartLine[] = cartLines;
    if (MsDyn365.isBrowser) {
        const bonusItemsInfoStr = localStorage.getItem('BonusItemProduct');
        if (bonusItemsInfoStr && bonusItemsInfoStr.length) {
            const bonusItems = JSON.parse(bonusItemsInfoStr);
            if (bonusItems && bonusItems.length) {
                const testVarStruct = bonusItems;
                for (const obj of testVarStruct) {
                    const deets = obj.BonusItemsDetails;
                    for (const dt of deets) {
                        // const coupon = dt.Coupon;
                        const itms = dt.Items;
                        for (const it of itms) {
                            const items = it.BonusItems;
                            // const qItemId = it.QualifyingItemId;
                            // const qpId = it.QualifyingProductId;
                            for (const itm of items) {
                                // itm.QualifyingItemId = qItemId;
                                // itm.Coupon = coupon;
                                // itm.QualifyingProductId = qpId;
                                for (let c = 0; c < cls.length; c++) {
                                    if (cls[c].ProductId?.toString() === itm.BonusProductId) {
                                        const getLocalStorage = localStorage.getItem('selectedBonasItems');
                                        let selectedBonusItemArray: IProductBonusItems[] = [];
                                        if (getLocalStorage) {
                                            selectedBonusItemArray = JSON.parse(getLocalStorage);
                                        }
                                        let isSelectedManually = false;
                                        if (hasArray(selectedBonusItemArray)) {
                                            for (const sbi of selectedBonusItemArray) {
                                                if (hasArray(sbi.children)) {
                                                    for (const childd of sbi.children) {
                                                        if (childd.BonusProductId?.toString() === itm.BonusProductId && sbi.parentId) {
                                                            const prodIndex = findIndexOfItemInArray(cls, sbi.parentId.toString());
                                                            arraymove(cls, c, prodIndex + 1);
                                                            isSelectedManually = true;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        if (!isSelectedManually) {
                                            const prodIndex = findIndexOfItemInArray(cls, itm.QualifyingProductId);
                                            arraymove(cls, c, prodIndex + 1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return cls;
};

export const getSetBasketCookie = (
    method: string,
    context: ICoreContext<{
        [x: string]: any;
    }>,
    basketCookieData?: IBasketCookie,
    cartLines?: CartLine[]
) => {
    const cookie = new Cookies();
    if (method === 'get') {
        return cookie.get('mfrm_basket_cookie');
    } else {
        const date = new Date();
        const cartSessionExpiration = (context.app && context.app.platform && context.app.platform.cartSessionExpiration) || 0;
        try {
            if (basketCookieData) {
                const cartSessionExpirationDateInDays = parseInt(cartSessionExpiration, 10);
                date.setDate(date.getDate() + cartSessionExpirationDateInDays);
                if (cartLines) {
                    const cartDataInfo: IBasketCookieCartInfo[] = [];
                    for (const cln of cartLines) {
                        cartDataInfo.push({
                            productId: cln.ProductId ? cln.ProductId : -1,
                            itemId: cln.ItemId ? cln.ItemId : '',
                            quantity: cln.Quantity ? cln.Quantity : 0
                        });
                    }
                    basketCookieData.cartDataInfo = cartDataInfo;
                }
                /* Update phone format from (xxx) xxx-xxxx to xxx-xxx-xxxx */
                if (basketCookieData.phoneNumber) {
                    const formattedPhoneNo = changePhoneFormatForAPI(basketCookieData.phoneNumber);
                    basketCookieData.phoneNumber = formattedPhoneNo;
                }
                cookie.set('mfrm_basket_cookie', basketCookieData, { expires: date });
                const cartId = cookie.get('_msdyn365___cart_');
                cookie.set('_msdyn365___cart_', cartId, { expires: date });
                cookie.set('last_cart_expiry_time', date);
            }
        } catch (error) {
            console.log('Error', error);
        }
    }
    return basketCookieData;
};

export const resetBasketCookie = () => {
    const cookie = new Cookies();
    const date = new Date();
    date.setDate(date.getDate() - 1);
    cookie.set('mfrm_basket_cookie', {}, { expires: date });
};

export const limitedTealiumArray = (arr: any[], limit: number): any[] => {
    return arr.length > limit ? arr.slice(0, limit) : arr;
};

export const setTealiumCartVariables = (
    name: string,
    context: ICoreContext<{
        [x: string]: any;
    }>,
    productDetails?: IProductDetails | IProductDetailsData | ICartProductUrl
) => {
    const cookie = new Cookies();
    const date = new Date();
    const cartSessionExpiration = (context.app && context.app.platform && context.app.platform.cartSessionExpiration) || 0;
    try {
        if (productDetails) {
            const cartSessionExpirationDateInDays = parseInt(cartSessionExpiration, 10);
            date.setDate(date.getDate() + cartSessionExpirationDateInDays);
            cookie.set(`${name}`, productDetails, { expires: date });
        }
    } catch (error) {
        console.log('Error', error);
    }
    return setTealiumCartVariables;
};

/** Following function is used to get 'Cart Alert Banner (aka Pencil Banner)' */
export const getProductAlertBanner = (context: ICoreContext<{ [x: string]: any; }>, productAttributes: AttributeValue[]) => {
    const { app : { config: { productAlertBannerAttribute } } } = context;
    const alertBannerAttribute = productAlertBannerAttribute?.toLowerCase().trim() || 'plp_ribbon';
    return productAttributes.find(attribute => attribute.Name?.toLowerCase().trim() === alertBannerAttribute)?.TextValue;
};
/**
   * VSI Customization - Retrieves the list of core delivery messages for cart items Ticket 105989.
*/
export const getListOfCoreDeliveryMessageCart = async (props: IMfrmCartProps<IMfrmCartData>, ctx: IActionContext, cartLines: IMFICartLine[], zipCode?: string): Promise<IMFIATPInventoryEntityPLPEx[] | undefined | { outOfMarket: string }> => {
    const data: IMFIATPInventoryPLPResponse = await fetchCoreDeliveryListAction(new FetchCoreDeliveryListInput(cartLines, zipCode), ctx);
    /**
    * The following code extracts a specific message from the provided data object:.
    * Data: "{\"Message\":\"No Zip Code Group found for the postal code 54149\"}"
    * The message indicates that no zip code group was found for the given postal code
    */
    const resultMessage = data?.Message?.slice(12, 50); // "No Zip Code Group found for the postal code", is assigned
    const outOfMarketMsg = props.context.app.config.responseMessageForItemOutOfMarketZip;
    const msgOutOfMarket = outOfMarketMsg?.slice(0, 38); // "No Zip Code Group found for the postal code", is assigned
    if (msgOutOfMarket === resultMessage || props.context.app.config.responseMessageForItemOutOfMarketOOZ?.slice(0, 31)?.trim()?.toLowerCase() === resultMessage?.slice(0, 31)?.trim()?.toLowerCase()) {
        return { outOfMarket: 'yes'};
    }
    const updatedResponse: IMFIATPInventoryEntityPLPEx[] | undefined = data?.ATPInventoryPLPData?.map((deliveryItem: IMFIATPInventoryEntityPLP, i: number) => {
        let deliveryMessage = '';
        if (deliveryItem.Available?.toLowerCase() === DeliveryDateDetails.AvailableYes && deliveryItem.ATPQuantity !== undefined && parseInt(deliveryItem.ATPQuantity, 10) > 0) {
            const date = new Date();
            const today = dayjs(Date.now()).format('D M YY');
            const deliveryDate = dayjs(deliveryItem.SlotDate).format('D M YY');
            if (today === deliveryDate) {
                deliveryMessage = `${DeliveryDateDetails.DeliveryToday} ${dayjs(deliveryItem.SlotDate).format('MMM D')}`;
            } else if (deliveryDate === dayjs(date.setDate(date.getDate() + 1)).format('D M YY')) {
                deliveryMessage = `${DeliveryDateDetails.DeliveryTomorrow} ${dayjs(deliveryItem.SlotDate).format('MMM D')}`;
            } else {
                deliveryMessage = `${DeliveryDateDetails.DeliveryBy} ${dayjs(deliveryItem.SlotDate).format('MMM D')}`;
            }
        } else {
            deliveryMessage = ctx.requestContext.app.config.defaultDeliveryMessage ? ctx.requestContext.app.config.defaultDeliveryMessage : '';
        }
        return { ...deliveryItem, deliveryMessage };
    });
    return updatedResponse;
};
// TODO: _coreProductATPCall and _coreProductATPCallCheckForOOM are public methods and should be renamed with addditional cleanup
export const __ = {
    _callAtpCallProductAndOOM,
    _coreProductATPCall,
    _coreProductATPCallCheckForOOM
};