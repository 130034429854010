export enum HeaderLevelAttributes {
    EcomFirstName = 'Ecom_CustomerFirstName',
    EcomLastName = 'Ecom_CustomerlastName',
    EcomPhone = 'Ecom_CustomerPhone',
    EcomShippingAddress2 = 'Ecom_shippingAddress2',
    EcomBilling = 'Ecom_isBillingSameAsShipping',
    EcomCardPayment = 'Ecom_cardPaymentInfo',
    EcomPromoCode = 'Ecom_PromoCodes',
    EcomCardPrefix = 'Ecom_cardPrefix',
    EmailCheckbox = 'email_checkbox',
    EcomCashback = 'Ecom_Cashback',
    EcomMonthly = 'Ecom_MonthlyAmount',
    EcomFinance = 'Ecom_FinanceOption',
    EcomPlanId = 'Ecom_FinancePlanId',
    EcomFinanceTotal = 'Ecom_FinanceAmount',
    EcomProgressiveCartId = 'Ecom_ProgressiveCartId',
    EcomProgressiveApplicationId = 'Ecom_ProgressiveApplicationId',
    EcomProgressiveInitialPayment = 'Ecom_ProgressiveInitialPayment',
    EcomProgressiveTax = 'Ecom_ProgressiveTax',
    EcomProgressiveTerm = 'Ecom_ProgressiveTerm',
    EcomProgressiveLeaseToOwnCost = 'Ecom_ProgressiveLeaseToOwnCost',
    EcomProgressiveTotalMonthLeaseToOwnCost = 'Ecom_ProgressiveTotalMonthLeaseToOwnCost'
}
export enum LineLevelAttributeName {
    EcomRevenueCategory = 'Ecom_RevenueCategory',
    EcomShippingMessage = 'Ecom_ShippingMessage',
    EcomShippingTitle = 'Ecom_ShippingTitle',
    EcomVariantId = 'Ecom_VariantId',
    BrandName = 'brandName',
    CategoryName = 'categoryName',
    EcomProductType = 'Ecom_ProductType',
    EcomDeliveryService = 'Ecom_DeliveryService',
    EcomProductNameWithBrand = 'Ecom_ProductNameWithBrand',
    ATPZipcode = 'Ecom_DSZipCode',
    InventLocationId = 'Ecom_InventLocationId',
    DeliveryDate = 'Ecom_DeliveryDate',
    EcomDSZipCode = 'Ecom_DSZipCode',
    EcomDSDeliveryScheduleStatus = 'Ecom_DSDeliveryScheduleStatus',
    EcomWillCallDateExists = 'Ecom_WillCallDateExists',
    EcomDSZoneLineId = 'Ecom_DSZoneLineId',
    EcomDeliveryTime = 'Ecom_DeliveryTime',
    EcomStartTime = 'Ecom_StartTime',
    EcomEndTime = 'Ecom_EndTime',
    EcomDSZone = 'Ecom_DSZone'
}