import { IDeliveryProps } from '../modules/mfrm-cart/mfrm-cart.view';

export interface IDeliveryServiceNames  {
    serviceProductRecId: number;
    serviceName: string;
    willCallMessage: string;
    willCallTitle?: string;
}

export const getDeliveryService = (deliveryServices: IDeliveryProps[]): number[] => {
    const newDeliveryServiceArray: number[] = [];
    deliveryServices?.forEach((item: IDeliveryProps) => {
        let serviceProductRecId = item.serviceProductRecId;
        newDeliveryServiceArray.push(serviceProductRecId);
        if(item?.childService) {
            item.childService.forEach((childItem) => {
                serviceProductRecId = childItem.productRectId!;
                newDeliveryServiceArray.push(serviceProductRecId);
            });
        }
    });

    return newDeliveryServiceArray;
};

export const getDeliveryServiceWithName = (deliveryServices: IDeliveryProps[]): IDeliveryServiceNames[] => {
    const newDeliveryServiceArray: IDeliveryServiceNames[] = [];
    deliveryServices?.forEach((item: IDeliveryProps) => {
        let serviceProductRecId = {serviceProductRecId: item.serviceProductRecId , serviceName: item.serviceName , willCallMessage: item.willCallMessage, willCallTitle: item?.willCallTitle };
        newDeliveryServiceArray.push(serviceProductRecId);
        if(item?.childService) {
            item.childService.map((childItem) => {
                serviceProductRecId = {serviceProductRecId: childItem.productRectId! , serviceName: item.serviceName , willCallMessage: item.willCallMessage, willCallTitle: item?.willCallTitle };
                newDeliveryServiceArray.push(serviceProductRecId);
            });
        }
    });

    return newDeliveryServiceArray;
};

export const getDeliveryServiceWithSKU = (deliveryServices: IDeliveryProps[]): { serviceProductRecId: number; serviceSku: string; addKeyInPrice: boolean }[] => {
    const newDeliveryServiceArray: { serviceProductRecId: number; serviceSku: string;  addKeyInPrice: boolean }[] = [];
    deliveryServices?.forEach((item: IDeliveryProps) => {
        const serviceItems = {serviceProductRecId: item.serviceProductRecId , serviceSku: item.serviceSku , addKeyInPrice: item.addKeyInPrice!};
        newDeliveryServiceArray.push(serviceItems);
        if(item?.childService) {
            item.childService.map((childItem) => {
                const serviceItems = {serviceProductRecId: childItem.productRectId! , serviceSku: childItem.serviceSKU! , addKeyInPrice: childItem.addKeyInPrice};
                newDeliveryServiceArray.push(serviceItems);
            });
        }
    });

    return newDeliveryServiceArray;
};