/* MFRMProductTypes enum is used to check/compare the product type with product_details cookie
by converting it to lower case used mainly in "conditional statements" */
export enum MFRMProductTypes {
    core = 'core',
    smallParcel = 'parcel',
    dropShip = 'drop ship'
}

// DeliveryModes enum is used to pass the modOfDelivery value in "API payloads"
export enum DeliveryModes {
    core = 'Delivery',
    smallParcel = 'Parcel',
    dropShip = 'Drop Ship'
}

// Enum for Delivery Date details messages show on cart page.
export enum DeliveryDateDetails {
    DeliveryToday = 'Delivery by Today',
    DeliveryTomorrow = 'Delivery by Tomorrow',
    DeliveryBy = 'Delivery by',
    AvailableYes = 'yes'
}

// coreItemShippingType constant value is used to compare the shipping type of core products
export const coreItemShippingType = 'delivery';

export enum EyeBrowMessage {
    BestSeller = "Best Seller",
    SleepExpertPick = "Sleep Expert® Pick",
    OnlyAtMattressFirm = "Only at Mattress Firm",
    SleepScoreValidated = "Sleep score validated",
    PLPRibbon = "Best Seller",
}

export enum EyeBrowAttributeName {
    BestSeller = 'BestSeller',
    SE_Pick = 'SE_Pick',
    IsExclusive = 'IsExclusive',
    SleepScore = 'SleepScore',
    PLP_Ribbon = 'PLP_Ribbon',
}
// this text is sent to rating component on cart page (as it is used on cart page so sending cart module name)
export const cartModuleName = 'mfrm-cart';

// Define an enum for local storage keys
export enum LocalStorageKeys {
    DropshipLatestDeliveryMsg = 'payment_review_dropship_latest_del_msg',
    ParcelLatestDeliveryMsg = 'payment_review_parcel_latest_del_msg',
    CustomerPhone = 'customer_phone',
    SelectedDeliveryService = 'selected_delivery_service',
    CustomerBillingSameDelivery = 'is_customer_billing_same_as_delivery',
    RecycleFee = 'recycleFee'
}
// this text is using for progressive value
export const progressiveName = 'Progressive';